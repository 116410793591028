import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{ LanguageService } from '../../services/language.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.css']
})
export class CarsComponent implements OnInit {

  cars_arr = [];
  selected_language = '';
  image_base_url = environment.image_base_url;
  environment = environment;
  car_brands = [];
  search_filter_arr:any = [];
  brands_arr = [];
  car_models = [];
  registration_years = [];
  brand_id = 0;
  model_id = 0;
  selected_registration_year = 0;
  pagination = [];
  i = 0;
  pagination_arr = [];
  current_page = 1;
  selected_price = 0;
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private LanguageService: LanguageService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Cars");
    this.LanguageService.currentMessage.subscribe(message => {
      this.selected_language = message;
      this.get_cars_list(1);
      this.get_searched_filters_from_home_page();
    })
  }

  get_cars_list(page_number) {
    this.current_page = page_number;
    let search_car_data = JSON.parse(localStorage.getItem('searchCar'));
    this.http.post<any>(environment.base_url+'/get_cars_list?page='+page_number, {
      filters:search_car_data
    }).subscribe(data => {
      if(data.success) {
        this.cars_arr = data.data.cars_arr.data;
        this.pagination = data.data.cars_arr;
        let page_arr = [];
        for(let i = 1; i<= data.data.cars_arr.last_page; i++) {
          page_arr.push({page:i})
        }
        this.pagination_arr = page_arr;
        //console.log(this.pagination);
        this.spinner.hide();
      } 
      else {
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")  
  }

  get_searched_filters_from_home_page() {
    let search_car_data = JSON.parse(localStorage.getItem('searchCar'));
    let selected_filters = JSON.parse(localStorage.getItem('selected_filters'));
    if(selected_filters != null && search_car_data != null) {
      this.brand_id = search_car_data.car_brand_id;
      this.model_id = search_car_data.car_model_id;
      this.selected_registration_year = search_car_data.registration_year;
      this.selected_price = search_car_data.price;

      this.brands_arr = selected_filters.brands_arr;
      this.car_models = selected_filters.car_models;
      this.registration_years = selected_filters.registration_years;
      this.get_filters(search_car_data.registration_year, 'year');

      localStorage.removeItem('searchCar');
      localStorage.removeItem('selected_filters');
    }
    else {
      this.get_filters('','');
    }
  }

  
  //Function to get filters array
  get_filters(value, type) {

    if(type == 'brand') {
      this.brand_id = value;
      this.model_id = 0;
      this.selected_registration_year = 0
    }

    if(type == 'model') {
      this.model_id = value;
      this.selected_registration_year = 0
    }

    if(type == 'year') {
      this.selected_registration_year = value;
    }

    this.http.post<any>(environment.base_url+'/get_search_filters', {
      brand_id: this.brand_id,
      model_id: this.model_id,
      registration_year: this.selected_registration_year,  
    }).subscribe(data => {
      if(data.success) {
        if(data.data.search_filter_arr.car_brands != undefined) {
          this.brands_arr = data.data.search_filter_arr.car_brands;
        }

        if(data.data.search_filter_arr.car_models != undefined) {
          this.car_models = data.data.search_filter_arr.car_models;
        }

        if(data.data.search_filter_arr.registration_years != undefined) {
          this.registration_years = data.data.search_filter_arr.registration_years;
        }
        this.search_filter_arr = data.data.search_filter_arr; 
        this.spinner.hide();
      } 
      else {
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")

  }

  search_car() {
    let search_car_arr = {
      "car_brand_id": "",
      "car_model_id": "",
      "registration_year": "",
      "price": "",   
    };
    var car_brand_id:any = document.getElementById("ingredients-first");
    search_car_arr.car_brand_id = car_brand_id.value;

    var car_model_id:any = document.getElementById("ingredients-second");
    search_car_arr.car_model_id = car_model_id.value;

    var registration_year:any = document.getElementById("ingredients-third");
    search_car_arr.registration_year = registration_year.value;

    var price:any = document.getElementById("ingredients-fourth");
    search_car_arr.price = price.value;
    
    localStorage.setItem('searchCar', JSON.stringify(search_car_arr));

    this.get_cars_list(1);
  }

  manage_view(view_type) {
    var list = document.getElementById("list-view");
    var grid = document.getElementById("grid-view");
    if(view_type == 'list') {
      grid.style.display = "none";
      list.style.display = "block";
    }
    else {
      grid.style.display = "block";
      list.style.display = "none";
    }
  }
}
