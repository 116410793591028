// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'https://carismaclassic.com/backend/api',
  image_base_url: 'https://carismaclassic.com/backend/public/uploads',
  languages: [
    'English',
    'Español',
    'Deutsche',
    'Pусский',
  ],
  languages_arr: [
    {
      'id' : 1,
      'code' : 'eng',
      'language' : 'English',
    },
    {
      'id' : 2,
      'code' : 'spa',
      'language' : 'Español',
    },
    {
      'id' : 3,
      'code' : 'ger',
      'language' : 'Deutsche',
    },
    {
      'id' : 4,
      'code' : 'rus',
      'language' : 'Pусский',
    },
  ],

  car_status: [
    {
      'eng' : 'Available',
      'spa' : 'Disponible',
      'ger' : 'Verfügbar',
      'rus' : 'Имеется в наличии',
    },
    {
      'eng' : 'New',
      'spa' : 'Nuevo',
      'ger' : 'Neu',
      'rus' : 'Новый',
    },
    {
      'eng' : 'Sold',
      'spa' : 'Vendido',
      'ger' : 'Verkauft',
      'rus' : 'Продано',
    },
    {
      'eng' : 'Reserved',
      'spa' : 'Reservado',
      'ger' : 'Reserviert',
      'rus' : 'Зарезервированный',
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
