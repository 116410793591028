<div class="bg-purple">
        
    <div class="stars">
       
        <div class="central-body">
            <img class="image-404" src="/assets/images/new.png" width="300px">
           
        </div>
        <div class="text_found">
            <p *ngIf="selected_language == 'eng'">You seems <strong>LOST!</strong>  Don't Worry though
            </p>
            <p *ngIf="selected_language == 'spa'">Tu pareces<strong>perdió</strong>Aunque no te preocupes
            </p>
            <p *ngIf="selected_language == 'ger'">Du scheinst
                <strong>hat verloren</strong>Mach dir aber keine Sorgen
            </p>
            <p *ngIf="selected_language == 'rus'">Ты кажешься<strong>потерянный</strong>Не волнуйся хотя </p>
        </div>
        <div class="central-bodys">
        <a [routerLink]="['']" *ngIf="selected_language == 'eng'" class="btn-go-home" target="_blank">GO BACK HOME</a>
        <a [routerLink]="['']" *ngIf="selected_language == 'spa'" class="btn-go-home" target="_blank">Regresar a casa</a>
        <a [routerLink]="['']" *ngIf="selected_language == 'ger'" class="btn-go-home" target="_blank">Wieder nach Hause gehen</a>
        <a [routerLink]="['']" *ngIf="selected_language == 'rus'" class="btn-go-home" target="_blank">возвращайся домой</a>
        



        </div>
        <div class="objects">
            <img class="object_rocket" src="/assets/images/car.png" width="40px">
            <div class="earth-moon">
              <img class="object_earth" src="http://salehriaz.com/404Page/img/earth.svg" width="100px">
              <img class="object_moon" src="http://salehriaz.com/404Page/img/moon.svg" width="80px">
          </div>
            <div class="box_astronaut">
                <img class="object_astronaut" src="/assets/images/cars.png" width="140px">
            </div>
        </div>
        <div class="glowing_stars">
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
  
        </div>
  
    </div>
  
  </div>