<section class="main-product" *ngIf="car != null">
    <div class="container">
        <div class="row mb-5">
            <div class="col-xl-7 col-md-6 col-12">
              <img [src]="image_base_url+'/car_feature_images/'+car.id+'/'+car_feature_image.img_name" alt="" title="">
            </div>
            <div class="col-xl-5 col-md-6 col-12 po-rel">
              <div class="pro-text">
                  <h4 *ngIf="selected_language == null || selected_language == 'eng'">{{selected_car_brand.eng_title}} {{selected_car_model.eng_title}} <span>{{car.eng_title}}</span></h4>
                  <h4 *ngIf="selected_language == 'spa'">{{selected_car_brand.spa_title}} {{selected_car_model.spa_title}} <span>{{car.spa_title}}</span></h4>
                  <h4 *ngIf="selected_language == 'ger'">{{selected_car_brand.ger_title}} {{selected_car_model.ger_title}} <span>{{car.ger_title}}</span></h4>
                  <h4 *ngIf="selected_language == 'rus'">{{selected_car_brand.rus_title}} {{selected_car_model.rus_title}} <span>{{car.rus_title}}</span></h4>
                  <p *ngIf="selected_language == null || selected_language == 'eng'">
                    {{car.short_eng_description | slice:0:170}}
                  </p>
                  <p *ngIf="selected_language == 'spa'">
                    {{car.short_spa_description}}
                  </p>
                  <p *ngIf="selected_language == 'ger'">
                    {{car.short_ger_description}}
                  </p>
                  <p *ngIf="selected_language == 'rus'">
                    {{car.short_rus_description}}
                  </p>
                  <table>
                    <tr>
                      <td *ngIf="selected_language == null || selected_language == 'eng'">mileage</td>
                      <td *ngIf="selected_language == 'spa'">kilometraje</td>
                      <td *ngIf="selected_language == 'ger'">Kilometerstand</td>
                      <td *ngIf="selected_language == 'rus'">пробег</td>
                      <td *ngIf="selected_language == null || selected_language == 'eng'">year</td>
                      <td *ngIf="selected_language == 'spa'">año</td>
                      <td *ngIf="selected_language == 'ger'">Jahr</td>
                      <td *ngIf="selected_language == 'rus'">год</td>
                      <td *ngIf="selected_language == null || selected_language == 'eng'">price</td>
                      <td *ngIf="selected_language == 'spa'">precio</td>
                      <td *ngIf="selected_language == 'ger'">Preis</td>
                      <td *ngIf="selected_language == 'rus'">цена</td>
                    </tr>
                    <tr>
                      <td class="value">{{car.km}}</td>
                      <td class="value">{{car.registration_year}}</td>
                      <td class="value">{{car.price}} €</td>
                    </tr>
                  </table>
                  <div class="row">
                    <div class="col-xl-6 col-12 mt-3 text-center">
                      <a *ngIf="selected_language == null || selected_language == 'eng'" class="btn get-btn">Get in Touch</a>
                      <a *ngIf="selected_language == 'spa'" class="btn get-btn">Ponerse en contacto</a>
                      <a *ngIf="selected_language == 'ger'" class="btn get-btn">In Kontakt kommen</a>
                      <a *ngIf="selected_language == 'rus'" class="btn get-btn">Связаться</a>
                    </div>
                    <div class="col-xl-6 col-12 mt-3 text-center"  *ngIf="selected_language == 'eng'">
                      <a (click)="download_pdf();" class="btn pdf-btn"><img src="assets/images/icons/download.svg" alt="" title=""> Download pdf</a>
                    </div>
                    <div class="col-xl-6 col-12 mt-3 text-center"  *ngIf="selected_language == 'spa'">
                      <a (click)="download_pdf();" class="btn pdf-btn"><img src="assets/images/icons/download.svg" alt="" title=""> Descargar PDF
                      </a>
                    </div>
                    <div class="col-xl-6 col-12 mt-3 text-center"  *ngIf="selected_language == 'ger'">
                      <a (click)="download_pdf();" class="btn pdf-btn"><img src="assets/images/icons/download.svg" alt="" title="">PDF Herunterladen

                      </a>
                    </div>

                    <div class="col-xl-6 col-12 mt-3 text-center"  *ngIf="selected_language == 'rus'">
                      <a (click)="download_pdf();" class="btn pdf-btn"><img src="assets/images/icons/download.svg" alt="" title="">Скачать PDF

                      </a>
                    </div>
                  </div>
              </div>
          </div>
          
        </div>
    </div>
</section>

<section class="product-description mt-5 mb-5" *ngIf="car != null">
    <div class="container">
      <div class="row">
        <div class="col-12 mt-5">
          <h2 *ngIf="selected_language == null || selected_language == 'eng'">DESCRIPTION</h2>
          <h2 *ngIf="selected_language == 'spa'">DESCRIPCIÓN</h2>
          <h2 *ngIf="selected_language == 'ger'">BESCHREIBUNG</h2>
          <h2 *ngIf="selected_language == 'rus'">ОПИСАНИЕ</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12 mt-3">
          <!-- <h6 class="mb-3">All time stars</h6> -->
          <p *ngIf="selected_language == null || selected_language == 'eng'" [innerHTML]="car.eng_description">
            {{car.eng_description}}
          </p>
          <p *ngIf="selected_language == 'spa'" [innerHTML]="car.spa_description">
            {{car.spa_description}}
          </p>
          <p *ngIf="selected_language == 'ger'" [innerHTML]="car.ger_description">
            {{car.ger_description}}
          </p>
          <p *ngIf="selected_language == 'rus'" [innerHTML]="car.rus_description">
            {{car.rus_description}}
          </p>
        </div>
        <!-- <div class="col-md-6 col-12">
          <h6 class="mb-3">All time stars</h6>
          <p>With 326 hp and 480 Nm, the sedan is in the ancestral series with the  W 109 280 SEL 6.3 and W 116 450 SEL 6.9: a sedan with the performance of a sports car.Visually, the 500 E can only be seen as  a second glance: discreetly widened fenders, integrated in the front apron fog lights and 16-inch alloy wheels in 8-hole design are its external distinguishing features.</p>
          <p>This restraint is certainly a factor for success, because until the end of production in April 1995, a total of 10,479 vehicles are built. Also in Zuffenhausen  the enthusiasm for the 500 E was great. Probably not only because of its spectacular performance.</p>
        </div> -->
      </div>
    </div>
</section>


<section class="product-deatils mt-5 mb-5" *ngIf="car != null">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12 mt-5">
          <div class="cards-data">
            <div class="container">
              <div id="accordion" class="accordion">
                  <div class="card mb-0">
                    <div class="card-header collapsed" aria-expanded="false" data-toggle="collapse" data-parent="#accordionSub" href="#collapseFive">
                        <a *ngIf="selected_language == null || selected_language == 'eng'" class="card-title">
                          Car Details
                        </a>
                        <a *ngIf="selected_language == 'spa'" class="card-title">
                           Detalles del coche
                        </a>
                        <a *ngIf="selected_language == 'ger'" class="card-title">
                           Autodetails
                        </a>
                        <a *ngIf="selected_language == 'rus'" class="card-title">
                           Детали автомобиля
                        </a>
                    </div>
                    <div id="collapseFive" class="card-body collapse" data-parent="#accordionSub" *ngIf="car_details.length > 0">
                      <table>
                        <tbody>
                          <tr *ngFor="let car_detail of car_details">
                            <td class="lft-text" *ngIf="selected_language == null || selected_language == 'eng'">{{car_detail.eng_label}}</td>
                            <td *ngIf="selected_language == null || selected_language == 'eng'">{{car_detail.eng_value}}</td>

                            <td class="lft-text" *ngIf="selected_language == 'spa'">{{car_detail.spa_label}}</td>
                            <td *ngIf="selected_language == 'spa'">{{car_detail.spa_value}}</td>

                            <td class="lft-text" *ngIf="selected_language == 'ger'">{{car_detail.ger_label}}</td>
                            <td *ngIf="selected_language == 'ger'">{{car_detail.ger_value}}</td>

                            <td class="lft-text" *ngIf="selected_language == 'rus'">{{car_detail.rus_label}}</td>
                            <td *ngIf="selected_language == 'rus'">{{car_detail.rus_value}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                      <div class="card-header collapsed" aria-expanded="false" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                        <a *ngIf="selected_language == null || selected_language == 'eng'" class="card-title">
                           Technical Data
                        </a>
                        <a *ngIf="selected_language == 'spa'" class="card-title">
                           Datos técnicos
                        </a>
                        <a *ngIf="selected_language == 'ger'" class="card-title">
                           Technische Daten
                        </a>
                        <a *ngIf="selected_language == 'rus'" class="card-title">
                           Технические данные
                        </a>
                      </div>
                      <div id="collapseTwo" class="card-body collapse" data-parent="#accordion" *ngIf="car_technical_details.length > 0">
                        <table>
                          <tbody>
                            <tr *ngFor="let car_technical_detail of car_technical_details">
                                <td class="lft-text" *ngIf="selected_language == null || selected_language == 'eng'">{{car_technical_detail.eng_label}}</td>
                                <td *ngIf="selected_language == null || selected_language == 'eng'">{{car_technical_detail.eng_value}}</td>
    
                                <td class="lft-text" *ngIf="selected_language == 'spa'">{{car_technical_detail.spa_label}}</td>
                                <td *ngIf="selected_language == 'spa'">{{car_technical_detail.spa_value}}</td>
    
                                <td class="lft-text" *ngIf="selected_language == 'ger'">{{car_technical_detail.ger_label}}</td>
                                <td *ngIf="selected_language == 'ger'">{{car_technical_detail.ger_value}}</td>
    
                                <td class="lft-text" *ngIf="selected_language == 'rus'">{{car_technical_detail.rus_label}}</td>
                                <td *ngIf="selected_language == 'rus'">{{car_technical_detail.rus_value}}</td>
                              </tr>
                          </tbody>
                        </table>
                      </div>
                  </div>
              </div>
          </div>
          </div>
      </div>
      <div class="col-md-6 col-12 mt-5">
        <div class="cards-data">
          <div class="container">
            <div id="accordionSub" class="accordion" >
                <div class="card mb-0">
                    <div class="card-header collapsed" aria-expanded="false" data-toggle="collapse" data-parent="#accordionSub" href="#collapseSeven">
                        <a *ngIf="selected_language == null || selected_language == 'eng'" class="card-title">
                           Individual Configuration
                        </a>
                        <a *ngIf="selected_language == 'spa'" class="card-title">
                           Configuración individual
                        </a>
                        <a *ngIf="selected_language == 'ger'" class="card-title">
                           Individuelle Konfiguration
                        </a>
                        <a *ngIf="selected_language == 'rus'" class="card-title">
                           Индивидуальная конфигурация
                        </a>
                    </div>
                    <div id="collapseSeven" class="card-body collapse" data-parent="#accordionSub" *ngIf="car_individual_config_details.length > 0">
                      <table>
                        <tbody>
                            <tr *ngFor="let car_individual_config_detail of car_individual_config_details">
                                <td class="lft-text" *ngIf="selected_language == null || selected_language == 'eng'">{{car_individual_config_detail.eng_label}}</td>
                                <td *ngIf="selected_language == null || selected_language == 'eng'">{{car_individual_config_detail.eng_value}}</td>
    
                                <td class="lft-text" *ngIf="selected_language == 'spa'">{{car_individual_config_detail.spa_label}}</td>
                                <td *ngIf="selected_language == 'spa'">{{car_individual_config_detail.spa_value}}</td>
    
                                <td class="lft-text" *ngIf="selected_language == 'ger'">{{car_individual_config_detail.ger_label}}</td>
                                <td *ngIf="selected_language == 'ger'">{{car_individual_config_detail.ger_value}}</td>
    
                                <td class="lft-text" *ngIf="selected_language == 'rus'">{{car_individual_config_detail.rus_label}}</td>
                                <td *ngIf="selected_language == 'rus'">{{car_individual_config_detail.rus_value}}</td>
                              </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
  </div>
</section>

<section class="product-gallery" *ngIf="car_images.length > 0">
<div class="container">
  <div class="row mt-5">
      <div class="col-md-4 col-12" (click)="show_all_pictures(image_index)" [class]="{'loadMore': image_index > 4}" *ngFor="let car_image of car_images;let image_index=index;">
        <div class="gallery-img mt-4" *ngIf="image_index < 5;else load_more">
          <img data-toggle="modal" style="cursor:pointer;" data-target="#videomodel" (click)="view_image(image_base_url+'/car_images/'+car_image.car_id+'/'+car_image.img);" [src]="image_base_url+'/car_images/'+car_image.car_id+'/'+car_image.img"  alt="" title="">
        </div>
        <ng-template #load_more>
          <div class="gallery-img mt-4" id="load_more_div" *ngIf="image_index == 5">
            <img [src]="image_base_url+'/car_images/'+car_image.car_id+'/'+car_image.img" alt="" title="">
            <div class="ga-content">
              <h5>+{{car_images.length-6}} <br><span>PHOTOS</span></h5>
            </div>
          </div>
          <div class="gallery-img mt-4" [class]="{'hide_car_images': image_index > 4}">
            <img data-toggle="modal" style="cursor:pointer;" data-target="#videomodel" (click)="view_image(image_base_url+'/car_images/'+car_image.car_id+'/'+car_image.img);" [src]="image_base_url+'/car_images/'+car_image.car_id+'/'+car_image.img" alt="" title="">
          </div>
        </ng-template>
      </div>
  </div>
</div>
</section>


<section class="related-description mt-5 mb-5">
  <div class="container">
    <div class="row">
     
        <div class="col-12 mt-5" *ngIf="selected_language == 'eng'">
          <h2>Choose your car</h2>
        </div>
        <div class="col-12 mt-5" *ngIf="selected_language == 'spa'">
          <h2>Elige tu coche
          </h2>
        </div>
        <div class="col-12 mt-5" *ngIf="selected_language == 'ger'">
          <h2>Wählen Sie Ihr Auto
          </h2>
        </div>
        <div class="col-12 mt-5" *ngIf="selected_language == 'rus'">
          <h2>Выбери свою машину</h2>
        </div>
      </div> 
  </div>
  <div class="rel-pro-form">
  <div class="rel-first-row">
    <div class="container">
        <div class="row horiztal-form">
            <div class="col-xl-10 col-md-12 col-12">
                <div class="row bold-text" *ngIf="brands_arr.length > 0">
                    <div class="col-xl-3 col-md-6 col-12">
                      <div class="form-group">
                        <label for="formGroupExampleInput" *ngIf="selected_language == null || selected_language == 'eng'">SELECT CAR</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'spa'">SELECCIONAR COCHE</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'ger'">AUTO AUSWÄHLEN</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'rus'">ВЫБЕРИТЕ АВТО</label>
                          <select id="ingredients-first" (change)="get_filters($event.target.value, 'brand')" *ngIf="selected_language == null || selected_language == 'eng'">
                            <option value="0">Select brand</option>
                            <option value="{{brand.id}}" *ngFor="let brand of brands_arr">{{brand.eng_title}}</option>
                          </select>
                          <select id="ingredients-first" (change)="get_filters($event.target.value, 'brand')" *ngIf="selected_language == 'spa'">
                            <option value="0">Seleccionar marca</option>
                            <option value="{{brand.id}}" *ngFor="let brand of brands_arr">{{brand.spa_title}}</option>
                          </select>
                          <select id="ingredients-first" (change)="get_filters($event.target.value, 'brand')" *ngIf="selected_language == 'ger'">
                            <option value="0">Marke auswählen</option>
                            <option value="{{brand.id}}" *ngFor="let brand of brands_arr">{{brand.ger_title}}</option>
                          </select>
                          <select id="ingredients-first" (change)="get_filters($event.target.value, 'brand')" *ngIf="selected_language == 'rus'">
                            <option value="0">Выберите марку</option>
                            <option value="{{brand.id}}" *ngFor="let brand of brands_arr">{{brand.rus_title}}</option>
                          </select>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <div class="form-group">
                          <label for="formGroupExampleInput" *ngIf="selected_language == null || selected_language == 'eng'">SELECT MODEL</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'spa'">SELECCIONAR MODELO</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'ger'">MODELL AUSWÄHLEN</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'rus'">ВЫБЕРИТЕ МОДЕЛЬ</label>
                          <select id="ingredients-second" (change)="get_filters($event.target.value, 'model')" *ngIf="selected_language == null || selected_language == 'eng'">
                            <option value="0">Select model</option>
                            <option value="{{car_model.id}}" *ngFor="let car_model of car_models">{{car_model.eng_title}}</option>
                          </select>
                          <select id="ingredients-second" (change)="get_filters($event.target.value, 'model')" *ngIf=" selected_language == 'spa'">
                            <option value="0">Seleccionar modelo</option>
                            <option value="{{car_model.id}}" *ngFor="let car_model of car_models">{{car_model.spa_title}}</option>
                          </select>
                          <select id="ingredients-second" (change)="get_filters($event.target.value, 'model')" *ngIf="selected_language == 'ger'">
                            <option value="0">Modell auswählen</option>
                            <option value="{{car_model.id}}" *ngFor="let car_model of car_models">{{car_model.ger_title}}</option>
                          </select>
                          <select id="ingredients-second" (change)="get_filters($event.target.value, 'model')" *ngIf="selected_language == 'rus'">
                            <option value="0">Выбрать модель</option>
                            <option value="{{car_model.id}}" class="car_model_field" *ngFor="let car_model of car_models">{{car_model.rus_title}}</option>
                          </select>
                          </div>
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <div class="form-group">
                          <label for="formGroupExampleInput" *ngIf="selected_language == null || selected_language == 'eng'">1ST REGISTRATION</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'spa'">1ER REGISTRO</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'ger'">1. REGISTRIERUNG</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'rus'">ПЕРВАЯ РЕГИСТРАЦИЯ</label>
                          <select  id="ingredients-third" (change)="get_filters($event.target.value, 'year')">
                            <option value="0" *ngIf="selected_language == null || selected_language == 'eng'">Select registration year</option>
                            <option value="0" *ngIf="selected_language == 'spa'">Seleccionar año de registro</option>
                            <option value="0" *ngIf="selected_language == 'ger'">Registrierungsjahr auswählen</option>
                            <option value="0" *ngIf="selected_language == 'rus'">Выберите год регистрации</option>
                            <option value="{{registration_year.registration_year}}" *ngFor="let registration_year of registration_years">{{registration_year.registration_year}}</option>
                          </select>
                          </div>
                    </div>
                    <div class="col-xl-3 col-md-6 col-12">
                        <div class="form-group">
                          <label for="formGroupExampleInput" *ngIf="selected_language == null || selected_language == 'eng'">PRICE UNTIL</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'spa'">PRECIO HASTA</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'ger'">PREIS BIS</label>
                          <label for="formGroupExampleInput" *ngIf="selected_language == 'rus'">ЦЕНА ДО</label>
                          <select  id="ingredients-fourth">
                            <option value="0" *ngIf="selected_language == null || selected_language == 'eng'">Select price</option>
                            <option value="0" *ngIf="selected_language == 'spa'">Seleccionar precio</option>
                            <option value="0" *ngIf="selected_language == 'ger'">Preis auswählen</option>
                            <option value="0" *ngIf="selected_language == 'rus'">Выберите цену</option>
                            <option value="{{price.price}}" *ngFor="let price of search_filter_arr.prices">{{price.price}}</option>
                          </select>
                          </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-md-12 col-12" *ngIf="selected_language == 'eng'">
                <div class="form-group-btn">
                    <button class="btn form-btn" (click)="search_car();">Select Your Car</button>
                  </div>
            </div>
            <div class="col-xl-2 col-md-12 col-12" *ngIf="selected_language == 'spa'">
              <div class="form-group-btn">
                  <button class="btn form-btn" (click)="search_car();">Seleccione su coche
                  </button>
                </div>
          </div>
          <div class="col-xl-2 col-md-12 col-12" *ngIf="selected_language == 'ger'">
            <div class="form-group-btn">
                <button class="btn form-btn" (click)="search_car();">Wählen Sie Ihr Auto
                </button>
              </div>
        </div>
        <div class="col-xl-2 col-md-12 col-12" *ngIf="selected_language == 'rus'">
          <div class="form-group-btn">
              <button class="btn form-btn" (click)="search_car();">Выберите свой автомобиль</button>
            </div>
      </div>
        </div>   
    </div>
    <div class="container product-list-rel" *ngIf="related_cars.length > 0">
      <div class="row">
        <div class="col-xl-3 col-md-6 col-12" *ngFor="let related_car of related_cars;let car_index=index;">
			
          <div class="card">
            <div class="card-header">
				<div class="viewmore">
				  <span>{{related_car.car_images.length}} +</span>
				</div>
				<div class="pro-img-left" *ngIf="related_car.car_images.length > 0">
					  <div id="demo{{car_index}}" class="carousel slide" data-ride="carousel">
						<!-- The slideshow -->
						<div class="carousel-inner">
						   <div class="carousel-item" [class] = "car_image_index == 0 ? 'active' : ''" *ngFor="let car_image of related_car.car_images; let car_image_index=index">
								<img style="max-height: 170px;" [src]="image_base_url+'/car_images/'+related_car.id+'/'+car_image.img">
						  </div>
						</div>

						<!-- Left and right controls -->
						<a class="carousel-control-prev" href="#demo{{car_index}}" data-slide="prev">
						  <span class="carousel-control-prev-icon"></span>
						</a>
						<a class="carousel-control-next" href="#demo{{car_index}}" data-slide="next">
						  <span class="carousel-control-next-icon"></span>
						</a>
					</div>
				</div>
				<img *ngIf="related_car.car_images.length <= 0" style="max-height: 170px;" [src]="image_base_url+'/car_feature_images/'+related_car.id+'/'+related_car.car_feature_image.img_name" alt="" title="">
              <div class="overlay" style="position:unset !important"></div>
            </div>
			 <a (click)="reloadUrl(related_car.car_brands.slug,related_car.slug)" style="cursor:pointer">
            <div class="card-body">
              <div class="card-content">
                <div class="card-text">
                    <table class="table">
                        <tbody>
						<tr *ngIf="selected_language == null || selected_language == 'eng'">
						  <th class="tabhead">Name</th>
						  <td>{{related_car.eng_title}}</td>
						</tr>
						
						 <tr *ngIf="selected_language == 'spa'">
						  <th class="tabhead">Nombre</th>
						 <td>{{related_car.eng_title}}</td>
						</tr>
						<tr *ngIf="selected_language == 'ger'">
						  <th class="tabhead">Name</th>
						  <td>{{related_car.eng_title}}</td>
						</tr>
						<tr *ngIf="selected_language == 'rus'">
						  <th class="tabhead">имя</th>
						 <td>{{related_car.eng_title}}</td>
						</tr>
                          <tr *ngIf="selected_language == null || selected_language == 'eng'">
                            <th class="tabhead">brand</th>
                            <td>{{related_car.car_brands.eng_title}}</td>
                          </tr>
                          <tr *ngIf="selected_language == 'spa'">
                            <th class="tabhead">marca</th>
                            <td>{{related_car.car_brands.spa_title}}</td>
                          </tr>
                          <tr *ngIf="selected_language == 'ger'">
                            <th class="tabhead">Marke</th>
                            <td>{{related_car.car_brands.ger_title}}</td>
                          </tr>
                          <tr *ngIf="selected_language == 'rus'">
                            <th class="tabhead">марка</th>
                            <td>{{related_car.car_brands.rus_title}}</td>
                          </tr>

                          <tr *ngIf="selected_language == null || selected_language == 'eng'">
                            <th class="tabhead">model</th>
                            <td>{{related_car.car_models.eng_title}}</td>
                          </tr>
                          <tr *ngIf="selected_language == 'spa'">
                            <th class="tabhead">modelo</th>
                            <td>{{related_car.car_models.spa_title}}</td>
                          </tr>
                          <tr *ngIf="selected_language == 'ger'">
                            <th class="tabhead">Modell-</th>
                            <td>{{related_car.car_models.ger_title}}</td>
                          </tr>
                          <tr *ngIf="selected_language == 'rus'">
                            <th class="tabhead">модель</th>
                            <td>{{related_car.car_models.rus_title}}</td>
                          </tr>
						  
							<tr *ngIf="selected_language == null || selected_language == 'eng'">
                            <th class="tabhead">Price</th>
                            <td>{{related_car.price}}</td>
                          </tr>
						   <tr *ngIf="selected_language == 'spa'">
                           <th class="tabhead">Precio</th>
                            <td>{{related_car.price}}</td>
                          </tr>
						  
						   <tr *ngIf="selected_language == 'ger'">
                           <th class="tabhead">Preis</th>
                            <td>{{related_car.price}}</td>
                          </tr>
						  
						    <tr *ngIf="selected_language == 'rus'">
                           <th class="tabhead">Цена</th>
                            <td>{{related_car.price}}</td>
                          </tr>
						  
                          <tr>
                            <th class="tabhead">km</th>
                            <td>{{related_car.km}}</td>
                          </tr>

                          <tr>
                            <th class="tabhead" *ngIf="selected_language == null || selected_language == 'eng'">year</th>
                            <th class="tabhead" *ngIf="selected_language == 'spa'">año</th>
                            <th class="tabhead" *ngIf="selected_language == 'ger'">Jahr</th>
                            <th class="tabhead" *ngIf="selected_language == 'rus'">год</th>
                            <td>{{related_car.registration_year}}</td>
                          </tr>

                        </tbody>
                      </table>
                </div>
              </div>
            </div>
			 </a>
          </div>
		 
        </div>
      </div>
    </div>
  </div>
  </div>
  
  <div class="container product-list-rel">
    <div class="row">
      <div class="col-12  mt-5 text-center" *ngIf="selected_language == 'eng'">
        <a [routerLink]="['/cars']"><button class="btn">See more cars</button></a>
      </div>
      <div class="col-12  mt-5 text-center" *ngIf="selected_language == 'spa'">
        <a [routerLink]="['/cars']"><button class="btn">Ver más coches        </button></a>
      </div>
      <div class="col-12  mt-5 text-center" *ngIf="selected_language == 'ger'">
        <a [routerLink]="['/cars']"><button class="btn">Sehen Sie mehr Autos</button></a>
      </div>
      <div class="col-12  mt-5 text-center" *ngIf="selected_language == 'rus'">
        <a [routerLink]="['/cars']"><button class="btn">Посмотреть больше автомобилей</button></a>
      </div>
    </div>
  </div>
</section>

<table id="car-pdf" class="header" style="font-family: 'Poppins', sans-serif;display: flex; flex-direction: column; padding: 3em; width: 1100px; margin:0 auto; background-color: #fff; border-radius: 5px;display:none;">
  <tbody>
     <tr>
        <td style="border-bottom: 1px solid #ccc;padding-bottom: 10px;">
           <table  class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse;">
              <tbody>
                 <tr>
                    <td><img style="width: 300px;" src="/assets/images/logo-black.svg" alt=""></td>
                    <td></td>
                    <td *ngIf="selected_language == null || selected_language == 'eng'" style="font-size: 20px; line-height: 30px; font-weight: 600;text-align: right; color: #002d33;">Car-ID: {{car.id}} <br> <span style="font-size: 16px; font-weight: 400;text-align: left;vertical-align: top; color: #545454;">{{car_url}}</span></td>
                    <td *ngIf="selected_language == 'spa'" style="font-size: 20px; line-height: 30px; font-weight: 600;text-align: right; color: #002d33;">Car-ID: {{car.id}} <br> <span style="font-size: 16px; font-weight: 400;text-align: left;vertical-align: top; color: #545454;">{{car_url}}</span></td>
                    <td *ngIf="selected_language == 'ger'" style="font-size: 20px; line-height: 30px; font-weight: 600;text-align: right; color: #002d33;">Auto-ID: {{car.id}} <br> <span style="font-size: 16px; font-weight: 400;text-align: left;vertical-align: top; color: #545454;">{{car_url}}</span></td>
                    <td *ngIf="selected_language == 'rus'" style="font-size: 20px; line-height: 30px; font-weight: 600;text-align: right; color: #002d33;">ID автомобиля: {{car.id}} <br> <span style="font-size: 16px; font-weight: 400;text-align: left;vertical-align: top; color: #545454;">{{car_url}}</span></td>
                 </tr>
              </tbody>
           </table>
        </td>
     </tr>
     <tr>
        <td>
           <table class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse;margin-top: 20px; margin-bottom: 20px;">
              <tbody>
               <tr>
                 <td colspan="2" style="padding-bottom: 15px;font-size: 25px; line-height: 30px; font-weight: 600;text-align: left;vertical-align: top; color: #002d33;">
                  <span *ngIf="selected_language == null || selected_language == 'eng'">{{selected_car_brand.eng_title}} {{selected_car_model.eng_title}} {{car.eng_title}} ({{car.registration_year}}) <br> <span style="font-size: 16px; font-weight: 400;text-align: left;vertical-align: top; color: #545454;">{{selected_car_model.eng_title}} {{car.eng_title}} - {{car.short_eng_description | slice:0:60}}</span></span>
                  <span *ngIf="selected_language == 'spa'">{{selected_car_brand.spa_title}} {{selected_car_model.spa_title}} {{car.spa_title}} ({{car.registration_year}}) <br> <span style="font-size: 16px; font-weight: 400;text-align: left;vertical-align: top; color: #545454;">{{selected_car_model.spa_title}} {{car.spa_title}} - {{car.short_spa_description | slice:0:60}}</span></span>
                  <span *ngIf="selected_language == 'ger'">{{selected_car_brand.ger_title}} {{selected_car_model.ger_title}} {{car.ger_title}} ({{car.registration_year}}) <br> <span style="font-size: 16px; font-weight: 400;text-align: left;vertical-align: top; color: #545454;">{{selected_car_model.ger_title}} {{car.ger_title}} - {{car.short_ger_description | slice:0:60}}</span></span>
                  <span *ngIf="selected_language == 'rus'">{{selected_car_brand.rus_title}} {{selected_car_model.rus_title}} {{car.rus_title}} ({{car.registration_year}}) <br> <span style="font-size: 16px; font-weight: 400;text-align: left;vertical-align: top; color: #545454;">{{selected_car_model.rus_title}} {{car.rus_title}} - {{car.short_rus_description | slice:0:60}}</span></span>
               </td>
                  <td style="padding-bottom: 15px;font-size: 25px; line-height: 30px; font-weight: 600;text-align: right;vertical-align: bottom; color: #002d33;">
                     € {{car.price}}<br> 
                     <span *ngIf="selected_language == null || selected_language == 'eng'" style="font-size: 16px; font-weight: 400;text-align: left; color: #545454;">Offered for: € {{car.price}}</span>
                     <span *ngIf="selected_language == 'spa'" style="font-size: 16px; font-weight: 400;text-align: left; color: #545454;">Ofrecido para: € {{car.price}}</span>
                     <span *ngIf="selected_language == 'ger'" style="font-size: 16px; font-weight: 400;text-align: left; color: #545454;">Angeboten für: € {{car.price}}</span>
                     <span *ngIf="selected_language == 'rus'" style="font-size: 16px; font-weight: 400;text-align: left; color: #545454;">Предлагается для: € {{car.price}}</span>
                  </td>
               </tr>
                 <tr>
                     <td style="padding-right: 10px;">
                        <img style="width: 100%;" [src]="image_base_url+'/car_feature_images/'+car.id+'/'+car_feature_image.img_name" alt="" title="">
                     </td>
                     <td style="padding-right: 10px;" *ngIf="car_images.length > 0">
                        <img style="width: 100%;" [src]="image_base_url+'/car_images/'+car_images[0].car_id+'/'+car_images[0].img" alt="" title="">
                     </td>
                     <td style="padding-right: 10px;" *ngIf="car_images.length >= 2">
                        <img style="width: 100%;" [src]="image_base_url+'/car_images/'+car_images[1].car_id+'/'+car_images[1].img" alt="" title="">
                     </td>
                 </tr>
              </tbody>
           </table>
        </td>
     </tr>
     <tr>
        <td>
           <table  class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse; border:1px solid #ccc; margin-bottom: 40px; padding:40px;">
              <tbody>
                 <tr>
                    <td *ngIf="selected_language == null || selected_language == 'eng'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Car Details</td>
                    <td *ngIf="selected_language == 'spa'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Detalles del coche</td>
                    <td *ngIf="selected_language == 'ger'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Autodetails</td>
                    <td *ngIf="selected_language == 'rus'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Детали автомобиля</td>
                 </tr>
                 <tr *ngIf="car_details.length > 0">
                     <td style="padding-right:20px;">
                        <table  style="width: 100%; border-collapse: collapse;">
                           <tbody>
                              <tr *ngFor="let pdf_car_detail of car_details;let index=index">
                                <td *ngIf="index < car_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.eng_label}}</td>
                                <td *ngIf="index < car_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.eng_value}}</td>
                                <td *ngIf="index < car_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.spa_label}}</td>
                                <td *ngIf="index < car_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.spa_value}}</td>
                                <td *ngIf="index < car_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.ger_label}}</td>
                                <td *ngIf="index < car_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.ger_value}}</td>
                                <td *ngIf="index < car_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.rus_label}}</td>
                                <td *ngIf="index < car_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.rus_value}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </td>
                     <td style="padding-left:20px;">
                        <table  style="width: 100%; border-collapse: collapse;">
                           <tbody>
                              <tr *ngFor="let pdf_car_detail of car_details;let index=index">
                                 <td *ngIf="index >= car_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.eng_label}}</td>
                                 <td *ngIf="index >= car_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.eng_value}}</td>
                                 <td *ngIf="index >= car_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.spa_label}}</td>
                                 <td *ngIf="index >= car_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.spa_value}}</td>
                                 <td *ngIf="index >= car_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.ger_label}}</td>
                                 <td *ngIf="index >= car_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.ger_value}}</td>
                                 <td *ngIf="index >= car_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.rus_label}}</td>
                                 <td *ngIf="index >= car_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.rus_value}}</td>
                               </tr>
                               <tr *ngIf="car_details.length / 2 != car_details_count">
                                 <td style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">&nbsp;</td>
                                 <td style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">&nbsp;</td>
                               </tr>
                           </tbody>
                        </table>
                     </td>
                 </tr>
                 <tr>
                   <td colspan="2" style="padding:20px;"></td>
                </tr>
                 <tr>
                    <td *ngIf="selected_language == null || selected_language == 'eng'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Technical Data</td>
                    <td *ngIf="selected_language == 'spa'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Datos técnicos</td>
                    <td *ngIf="selected_language == 'ger'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Technische Daten</td>
                    <td *ngIf="selected_language == 'rus'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Технические данные</td>
                 </tr>
                 <tr *ngIf="car_technical_details.length > 0">
                  <td style="padding-right:20px;">
                     <table  style="width: 100%; border-collapse: collapse;">
                        <tbody>
                           <tr *ngFor="let pdf_car_detail of car_technical_details;let index=index">
                             <td *ngIf="index < technical_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.eng_label}}</td>
                             <td *ngIf="index < technical_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.eng_value}}</td>
                             <td *ngIf="index < technical_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.spa_label}}</td>
                             <td *ngIf="index < technical_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.spa_value}}</td>
                             <td *ngIf="index < technical_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.ger_label}}</td>
                             <td *ngIf="index < technical_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.ger_value}}</td>
                             <td *ngIf="index < technical_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.rus_label}}</td>
                             <td *ngIf="index < technical_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.rus_value}}</td>
                           </tr>
                        </tbody>
                     </table>
                  </td>
                  <td style="padding-left:20px;">
                     <table  style="width: 100%; border-collapse: collapse;">
                        <tbody>
                           <tr *ngFor="let pdf_car_detail of car_technical_details;let index=index">
                              <td *ngIf="index >= technical_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.eng_label}}</td>
                              <td *ngIf="index >= technical_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.eng_value}}</td>
                              <td *ngIf="index >= technical_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.spa_label}}</td>
                              <td *ngIf="index >= technical_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.spa_value}}</td>
                              <td *ngIf="index >= technical_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.ger_label}}</td>
                              <td *ngIf="index >= technical_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.ger_value}}</td>
                              <td *ngIf="index >= technical_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.rus_label}}</td>
                              <td *ngIf="index >= technical_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.rus_value}}</td>
                            </tr>
                            <tr *ngIf="car_technical_details.length / 2 != technical_details_count">
                              <td style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">&nbsp;</td>
                              <td style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">&nbsp;</td>
                            </tr>
                        </tbody>
                     </table>
                  </td>
                 </tr>
                 <tr>
                   <td colspan="2" style="padding:20px;"></td>
                </tr>
                 <tr>
                    <td *ngIf="selected_language == null || selected_language == 'eng'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Individual configuration</td>
                    <td *ngIf="selected_language == 'spa'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Configuración individual</td>
                    <td *ngIf="selected_language == 'ger'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">TIndividuelle Konfiguration</td>
                    <td *ngIf="selected_language == 'rus'" colspan="2" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Индивидуальная конфигурация</td>
                 </tr>
                 <tr *ngIf="car_individual_config_details.length > 0">
                  <td style="padding-right:20px;">
                     <table  style="width: 100%; border-collapse: collapse;">
                        <tbody>
                           <tr *ngFor="let pdf_car_detail of car_individual_config_details;let index=index">
                             <td *ngIf="index < individual_details_count && selected_language == null || selected_language == 'eng'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.eng_label}}</td>
                             <td *ngIf="index < individual_details_count && selected_language == null || selected_language == 'eng'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.eng_value}}</td>
                             <td *ngIf="index < individual_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.spa_label}}</td>
                             <td *ngIf="index < individual_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.spa_value}}</td>
                             <td *ngIf="index < individual_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.ger_label}}</td>
                             <td *ngIf="index < individual_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.ger_value}}</td>
                             <td *ngIf="index < individual_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.rus_label}}</td>
                             <td *ngIf="index < individual_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.rus_value}}</td>
                           </tr>
                        </tbody>
                     </table>
                  </td>
                  <td style="padding-left:20px;">
                     <table  style="width: 100%; border-collapse: collapse;">
                        <tbody>
                           <tr *ngFor="let pdf_car_detail of car_individual_config_details;let index=index">
                              <td *ngIf="index >= individual_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.eng_label}}</td>
                              <td *ngIf="index >= individual_details_count && (selected_language == null || selected_language == 'eng')" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.eng_value}}</td>
                              <td *ngIf="index >= individual_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.spa_label}}</td>
                              <td *ngIf="index >= individual_details_count && selected_language == 'spa'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.spa_value}}</td>
                              <td *ngIf="index >= individual_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.ger_label}}</td>
                              <td *ngIf="index >= individual_details_count && selected_language == 'ger'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.ger_value}}</td>
                              <td *ngIf="index >= individual_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">{{pdf_car_detail.rus_label}}</td>
                              <td *ngIf="index >= individual_details_count && selected_language == 'rus'" style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">{{pdf_car_detail.rus_value}}</td>
                            </tr>
                            <tr *ngIf="car_individual_config_details.length / 2 != individual_details_count">
                              <td style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 600;text-align: left; color: #545454;">&nbsp;</td>
                              <td style="padding:8px;font-size: 16px; border-top: 1px solid #ccc; font-weight: 400;text-align: left; color: #545454;">&nbsp;</td>
                            </tr>
                        </tbody>
                     </table>
                  </td>
                 </tr>
              </tbody>
           </table>
        </td>
     </tr>
     <tr>
       <td>
         <table  class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse; border:1px solid #ccc; margin-bottom: 40px; padding:40px;">
            <tbody>
               <tr>
                  <td *ngIf="selected_language == null || selected_language == 'eng'" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Description</td>
                  <td *ngIf="selected_language == 'spa'" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Descripción</td>
                  <td *ngIf="selected_language == 'ger'" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Beschreibung</td>
                  <td *ngIf="selected_language == 'rus'" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Описание</td>
               </tr>
               <tr>
                  <td style="padding:8px;">
                     <table  style="width: 100%; border-collapse: collapse;">
                        <tbody>
                           <tr>
                              <td *ngIf="selected_language == null || selected_language == 'eng'" style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 600;text-align: left; color: #545454;" [innerHTML]="car.eng_description">{{car.eng_description}}</td>
                              <td *ngIf="selected_language == 'spa'" style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 600;text-align: left; color: #545454;" [innerHTML]="car.spa_description">{{car.spa_description}}</td>
                              <td *ngIf="selected_language == 'ger'" style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 600;text-align: left; color: #545454;" [innerHTML]="car.ger_description">{{car.ger_description}}</td>
                              <td *ngIf="selected_language == 'rus'" style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 600;text-align: left; color: #545454;" [innerHTML]="car.rus_description">{{car.rus_description}}</td>
                           </tr>

                        </tbody>
                     </table>
                  </td>
               </tr>
            </tbody>
         </table>
      </td>
     </tr>
     <tr>
        <td>
         <table  class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse; border:1px solid #ccc; margin-bottom: 40px; padding:40px;">
            <tbody>
               <tr>
                  <td *ngIf="selected_language == null || selected_language == 'eng'" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Seller</td>
                  <td *ngIf="selected_language == 'spa'" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Vendedora</td>
                  <td *ngIf="selected_language == 'ger'" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Verkäuferin</td>
                  <td *ngIf="selected_language == 'rus'" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Продавец</td>
               </tr>
               <tr>
                  <td style="padding:10px;">
                     <table  style="width: 100%; border-collapse: collapse;">
                        <tbody>
                           <tr>
                              <td style="padding:8px 8px 0px 8px;font-size: 16px; vertical-align:top; font-weight: 600;text-align: left; color: #545454;"><img style="width: 250px;" src="/assets/images/logo-black.svg" alt=""></td>
                              <td style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 400;text-align: left; color: #545454;">
                                 21 Sunset Blvd,<br>
                                 Los Angeles California, <br>
                                 90453
                              </td>
                              <td>
                                <table  style="width: 100%; border-collapse: collapse;">
                                   <tbody>
                                      <tr>
                                         <td style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 600;text-align: left; color: #545454;">Contact: </td>
                                         <td style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 400;text-align: left; color: #545454;">Carisma Classic</td>
                                      </tr>
                                      <tr>
                                         <td style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 600;text-align: left; color: #545454;">Mobile: </td>
                                         <td style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 400;text-align: left; color: #545454;">+34 673581561</td>
                                      </tr>
                                      <tr>
                                         <td style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 600;text-align: left; color: #545454;">E-Mail: </td>
                                         <td style="padding:8px 8px 0px 8px;font-size: 16px; font-weight: 400;text-align: left; color: #545454;">info@carismaclassic.com</td>
                                      </tr>
                                   </tbody>
                                </table>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </td>
               </tr>
            </tbody>
         </table>
      </td>
     </tr>
     <!-- <tr>
        <td>
         <table  class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse; border:1px solid #ccc; margin-bottom: 40px; padding:40px;">
            <tbody>
               <tr>
                  <td style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Location</td>
               </tr>
               <tr>
                  <td>
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.4059018502494!2d76.80290231461012!3d30.706987493925045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fed8002442497%3A0x3522fe07bdc7102e!2sCar%20Bazar%20Chandigarh!5e0!3m2!1sen!2sin!4v1613117959307!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                  </td>
               </tr>
            </tbody>
         </table>
      </td>
     </tr> -->
     <tr>
        <td>
         <table  class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse; border:1px solid #ccc; margin-bottom: 40px; padding:40px;">
            <tbody>
               <tr>
                  <td *ngIf="selected_language == null || selected_language == 'eng'" colspan="3" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Pictures</td>
                  <td *ngIf="selected_language == 'spa'" colspan="3" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Fotos</td>
                  <td *ngIf="selected_language == 'ger'" colspan="3" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Bilder</td>
                  <td *ngIf="selected_language == 'rus'" colspan="3" style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Картинки</td>
               </tr>


               <tr *ngIf="pdf_images.length > 0">
                  <td colspan="3">
                     <table  class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse;  margin-bottom: 40px; padding:40px;">
                        <tbody>
                          <tr *ngFor="let pdf_image of pdf_images">
                             <td style="padding:10px 8px 0px 8px;" *ngFor="let pdf_img of pdf_image">
                                 <img style="width: 100%;" [src]="image_base_url+'/car_images/'+pdf_img.car_id+'/'+pdf_img.img" alt="">
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </td>
               </tr>
            </tbody>
         </table>
      </td>
     </tr>
     <!-- <tr>
        <td>
         <table  class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse; border:1px solid #ccc; margin-bottom: 40px; padding:40px;">
            <tbody>
               <tr>
                  <td style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Individual notes</td>
               </tr>
               <tr>
                 <td style="padding:8px 8px 10px 8px;font-size: 16px; font-weight: 400;text-align: left; color: #545454;">Es handelt sich hier um eine klassisches Maklertätigkeit, von Privat an Privat. Die Courtage und alle anfallenden Kosten bezahlt der Verkäufer. Ihnen als Käufer entstehen keine Provision-/ Vermittlungsgebühren. 100% Courtagefrei! Preisänderung, Irrtum und Zwischenverkauf vorbehalten. Für dieses Fahrzeug kann laut §25a UStG. keine Umsatz-/ Mehrwertsteuer ausgewiesen werden.</td>
               </tr>
            </tbody>
         </table>
      </td>
     </tr> -->
     <!-- <tr>
        <td>
         <table  class="invoice-table-head" style="background-color: #fff;width: 100%; border-collapse: collapse; border:1px solid #ccc; margin-bottom: 40px; padding:40px;">
            <tbody>
               <tr>
                  <td style="padding:10px;font-size: 20px; background-color: rgb(238, 237, 237); font-weight: 600;text-align: left; color: #002d33;">Classic Trader checklist</td>
               </tr>
               <tr>
                 <td style="padding:10px 8px 15px 8px;font-size: 16px; font-weight: 400;text-align: left; color: #545454;">Please check whether the vehicle (including equipment and accessories) generally corresponds to the description of the seller. In addition, you should pay attention to the following points:</td>
               </tr>
               <tr>
                 <td style="display: flex;padding:8px;font-size: 16px; font-weight: 400;text-align: left; line-height:22px; color: #545454;"><img style="vertical-align: middle;padding-right: 10px;padding-top: 2px;width: 18px;height:18px;" class="w-100" src="../pdf/images/check.png" alt=""> Are all important vehicle-documents present (e. g. registration documents)?</td>
               </tr>
               <tr>
                 <td style="display: flex;padding:8px;font-size: 16px; font-weight: 400;text-align: left; line-height:22px; color: #545454;"><img style="vertical-align: middle;padding-right: 10px;padding-top: 2px;width: 18px;height:18px;" class="w-100" src="../pdf/images/check.png" alt=""> Are there other documents present (historic-registration, operating licence, service history, certificates, evidence of history, documentation of the restoration, etc.)?</td>
               </tr>
               <tr>
                 <td style="display: flex;padding:8px;font-size: 16px; font-weight: 400;text-align: left; line-height:22px; color: #545454;"><img style="vertical-align: middle;padding-right: 10px;padding-top: 2px;width: 18px;height:18px;" class="w-100" src="../pdf/images/check.png" alt=""> Have you compared the vehicles chassis number with the documents?</td>
               </tr>
               <tr>
                 <td style="display: flex;padding:8px;font-size: 16px; font-weight: 400;text-align: left; line-height:22px; color: #545454;"><img style="vertical-align: middle;padding-right: 10px;padding-top: 2px;width: 18px;height:18px;" class="w-100" src="../pdf/images/check.png" alt=""> Does the vehicle's mileage correspond with the description of the seller?</td>
               </tr>
               <tr>
                 <td style="display: flex;padding:8px;font-size: 16px; font-weight: 400;text-align: left; line-height:22px; color: #545454;"><img style="vertical-align: middle;padding-right: 10px;padding-top: 2px;width: 18px;height:18px;" class="w-100" src="../pdf/images/check.png" alt=""> Have you checked all external and internal functions and instruments of the vehicle (lighting, heating etc.)?</td>
               </tr>
               <tr>
                 <td style="display: flex;padding:8px;font-size: 16px; font-weight: 400;text-align: left; line-height:22px; color: #545454;"><img style="vertical-align: middle;padding-right: 10px;padding-top: 2px;width: 18px;height:18px;" class="w-100" src="../pdf/images/check.png" alt=""> Did you check the brakes (handbrake, foot brake)?</td>
               </tr>
               <tr>
                 <td style="display: flex;padding:8px;font-size: 16px; font-weight: 400;text-align: left; line-height:22px; color: #545454;"><img style="vertical-align: middle;padding-right: 10px;padding-top: 2px;width: 18px;height:18px;" class="w-100" src="../pdf/images/check.png" alt=""> Did you check the engine (e. g. running smoothness, unusual sounds)?</td>
               </tr>
               <tr>
                 <td style="display: flex;padding:8px;font-size: 16px; font-weight: 400;text-align: left; line-height:22px; color: #545454;"><img style="vertical-align: middle;padding-right: 10px;padding-top: 2px;width: 18px;height:18px;" class="w-100" src="../pdf/images/check.png" alt=""> Does the condition of the vehicle correspond to the description of the seller?</td>
               </tr>
            </tbody>
         </table>
      </td>
     </tr> -->
  </tbody>
</table>


<!-- Modal -->
<div class="modal" id="videomodel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
  <div class="modal-content modal-xl">
    <div class="modal-body">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <img [src]="view_img_url" width="100%">
    </div>
    
  </div>
  </div>
  </div>