import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import{ LanguageService } from '../services/language.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  selected_language = '';
  constructor(  private spinner: NgxSpinnerService, private LanguageService: LanguageService) { }

  ngOnInit(): void {
    this.LanguageService.currentMessage.subscribe(message => {
      
      this.selected_language = message;
    })
    this.spinner.hide();
    
  }

}
