import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import{ LanguageService } from '../../services/language.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  selected_language = '';
  header_menus = [];
  selected_language_id = '';
  header_img_url = '';
  footer_img_url = '';
  constructor(
    private LanguageService: LanguageService,
    private http: HttpClient,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router,
    private spinner: NgxSpinnerService
  ) 
  {
  }
  
  ngOnInit(): void {
    let language = localStorage.getItem('selected_language');
    if(language == null){
      this.change_language('eng')
    }
    this.get_header_data();
    var overlay = document.getElementById('navbarResponsive');
    document.onclick = function(e:any){
      overlay.classList.remove('show');
    };
  }

  change_language(language) {
    //window.location.href=window.location.href.split('?')[0]+'?lang='+language;
    localStorage.setItem('selected_language', language);
    this.selected_language = language;
    this.LanguageService.changeMessage(language)
    this.get_header_data();
  }

  get_header_data() {
    this.http.get<any>(environment.base_url+'/get_header_menu').subscribe(data => {
      if(data.success) {
        //console.log(data);
        this.header_menus = data.data.menus_arr;
        this.header_img_url = environment.image_base_url+'/'+data.data.logos[0].type+'/'+data.data.logos[0].img;
        this.footer_img_url = environment.image_base_url+'/'+data.data.logos[1].type+'/'+data.data.logos[1].img;
        this.spinner.hide();
      } 
      else {
        this.toastr.error(data.msg.text, "")  
        this.spinner.hide();
      }
    }),
    (error) => { this.toastr.error(error.message, ""); this.spinner.hide(); }

    if (localStorage.getItem('selected_language')) { 
      this.selected_language = localStorage.getItem('selected_language');
    }
   
  }

}
