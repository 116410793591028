import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import{ LanguageService } from '../services/language.service';
import { DomSanitizer } from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  selected_language = '';
  image_base_url = environment.image_base_url;
  blogs = [];
  video_url:any = '';
  blog_id = '';
  customOptions: OwlOptions = {
    autoplay: true,
    lazyLoad: true,
    loop: true,
    margin: 20,
    //responsiveClass: true,
    autoHeight: true,
    autoplayTimeout: 1000,
    smartSpeed: 1000,
    nav: false,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
  
      600: {
        items: 2
      },
  
      1024: {
        items: 3
      },
  
      1366: {
        items: 3
      }
    }
  }
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private LanguageService: LanguageService,
    private dom:DomSanitizer,
    private route: ActivatedRoute,
    private titleService: Title
  ) 
  { 
    this.video_url = this.dom.bypassSecurityTrustResourceUrl("");
  }

  ngOnInit(): void {
    this.titleService.setTitle("Blogs");
    this.route.params.subscribe(params => {
      this.blog_id = params['id'];
    });
    this.LanguageService.currentMessage.subscribe(message => {
      this.selected_language = message;
      this.get_all_blogs();
    })
  }

  get_all_blogs() {
    this.http.get<any>(environment.base_url+'/get_blog/'+this.blog_id).subscribe(data => {
      if(data.success) {
        console.log(data.data);
        this.blogs = data.data;
        this.spinner.hide();
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //Function to play video in bootstrap model
  play_video(video_id) {
    this.video_url = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+video_id);
  }

}
