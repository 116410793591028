
<div class="process-area">
    <div class="container">
        
        <div class="row align-item-stretch">
            <div class="col-md-3">
                <div class="inside-block">
                <!-- Start single feature -->
                <div class="single-feature style-2  aos-init aos-animate">
                    <div class="left-icon">
                        <img src="/assets/images/1.png"  alt="Create your offer">
        </div>
              
                  
        
                      <h2 class="trans" *ngIf="selected_language == 'eng'">Transport with Carisma Classic</h2>
                    <h2 class="trans" *ngIf="selected_language == 'spa'">Transporte con Carisma Classic</h2>
                    <h2 class="trans" *ngIf="selected_language == 'ger'">Transport mit Carisma Classic</h2>
                    <h2 class="trans" *ngIf="selected_language == 'rus'">Транспорт с Carisma Classic</h2>
                    <p *ngIf="selected_language == 'eng'">We offer the transport service through Europe of your Classic Car.</p>
                    <p *ngIf="selected_language == 'spa'">Ofrecemos el servicio de transporte por Europa de su coche clásico.                  </p>
                    <p *ngIf="selected_language == 'ger'">Wir bieten den Transport Ihres Oldtimers durch Europa an.</p>
                    <p *ngIf="selected_language == 'rus'">Мы предлагаем услуги по транспортировке вашего классического автомобиля по Европе.
                    </p>
                  
                    <div class="btn custom-btn ng-star-inserted">
                        <a *ngIf="selected_language == 'eng'" href="#">Read More</a>
                        <a *ngIf="selected_language == 'spa'" href="#">Leer más</a>
                        <a *ngIf="selected_language == 'ger'" href="#">Mehr lesen</a>
                        <a *ngIf="selected_language == 'rus'" href="#">Читать далее</a>
                    </div>
                </div>
                <!-- End single feature -->
                <!-- Start single feature -->
                <div class="single-feature style-2  aos-init res_sec" >
                    <div class="left-icon">
                        <img src="/assets/images/4.png" alt="Create your offer">
        </div>
                 
                    <h2 class="restauracion" *ngIf="selected_language == 'eng'">Sale on Commission</h2>
                  <h2 class="restauracion" *ngIf="selected_language == 'spa'">Venta en comisión </h2>
                  <h2 class="restauracion" *ngIf="selected_language == 'ger'">Verkauf auf Kommission </h2>
                  <h2 class="restauracion" *ngIf="selected_language == 'rus'">Продажа на комиссионных началах</h2>
                  <p *ngIf="selected_language == 'eng'">Carisma Classic service to sell your classic car on Commission. We advertise the car in the internet, make pictures and prepare it for the german TUV.  </p>
                  <p *ngIf="selected_language == 'ger'">Carisma Classic Service zum Verkauf Ihres Oldtimers in Kommission. Wir inserieren das Auto im Internet, machen Bilder und bereiten es für den deutschen TÜV vor.  </p>
                  <p *ngIf="selected_language == 'spa'">Carisma Classic servicio para vender su coche clásico en la Comisión. Anunciamos el coche en internet, hacemos fotos y lo preparamos para el TUV alemán. </p>
                    <p *ngIf="selected_language == 'rus'">Carisma Classic - услуга по продаже вашего классического автомобиля на комиссию. Мы рекламируем автомобиль в интернете, делаем фотографии и готовим его к прохождению немецкого TUV.  </p>
                    <div class="btn custom-btn ng-star-inserted">
                      <a *ngIf="selected_language == 'eng'" href="#">Read More</a>
                      <a *ngIf="selected_language == 'spa'" href="#">Leer más</a>
                      <a *ngIf="selected_language == 'ger'" href="#">Mehr lesen</a>
                      <a *ngIf="selected_language == 'rus'" href="#">Читать далее</a>
                    </div>
                </div>
                <!-- End single feature -->
            </div></div>
            <div class="col-md-6  align-item-end">
                <div class="process-drone text-center aos-init aos-animate">
                    <img src="/assets/images/servicios.jpg" alt="">
                
                </div>
            </div>
            <div class="col-md-3">
                <div class="inside-block">
                <!-- Start single feature -->
                <div class="single-feature text-right aos-init aos-animate" >
                    <div class="left-icon">
                        <img src="/assets/images/3.png"  alt="Create your offer">
        </div>
                  
                    <h2 class="comission" *ngIf="selected_language == 'eng'">Classic Car Restoration </h2>
                    <h2 class="comission" *ngIf="selected_language == 'spa'">Restauración de coches clásicos </h2>
                    <h2 class="comission" *ngIf="selected_language == 'ger'">Oldtimer-Restaurierung </h2>
                    <h2 class="comission" *ngIf="selected_language == 'rus'">Реставрация классических автомобилей </h2>
                    <p *ngIf="selected_language == 'eng'">Carisma Classic service to restore your classic car and bring it back to its glory. </p>
                      <p *ngIf="selected_language == 'spa'">Servicio de Carisma Classic para restaurar su coche clásico y devolverle su esplendor. </p>
                      <p *ngIf="selected_language == 'ger'">Carisma Classic Service, um Ihren Oldtimer zu restaurieren und ihm wieder zu seinem Glanz zu verhelfen.</p>
                      <p *ngIf="selected_language == 'rus'">Услуга Carisma Classic по восстановлению вашего классического автомобиля и возвращению ему его славы.</p>
  
                    <div class="btn custom-btn ng-star-inserted">
                        <a href="#">Read More</a>
                    </div>
                </div>
                <!-- End single feature -->
                <!-- Start single feature -->
                <div class="single-feature text-right  style-2 aos-init res_sec">
                    <div class="left-icon">
                        <img src="/assets/images/2.png" alt="Create your offer">
        </div>
                
                    <h2 class="mantenimient" *ngIf="selected_language == 'eng'">Classic Car Maintenance</h2>
                    <h2 class="mantenimient" *ngIf="selected_language == 'spa'">Mantenimiento de coches clásicos</h2>
                    <h2 class="mantenimient" *ngIf="selected_language == 'ger'">Oldtimer-Wartung</h2>
                    <h2 class="mantenimient" *ngIf="selected_language == 'rus'">Обслуживание классических автомобилей</h2>
                    <p *ngIf="selected_language == 'eng'">Carisma Classic offers to our customers the maintenance services for the classic cars. Regular maintenance, paint, tires and much more.</p>
                    <p *ngIf="selected_language == 'spa'">Carisma Classic ofrece a nuestros clientes los servicios de mantenimiento para los coches clásicos. Mantenimiento regular, pintura, neumáticos y mucho más.</p>
                    <p *ngIf="selected_language == 'ger'">Carisma Classic bietet unseren Kunden die Wartung der Oldtimer an. Regelmäßige Wartung, Lackierung, Reifen und vieles mehr.</p>
                    <p *ngIf="selected_language == 'rus'">Carisma Classic предлагает нашим клиентам услуги по техническому обслуживанию классических автомобилей. Регулярное техническое обслуживание, покраска, шины и многое другое.</p>
                    <div class="btn custom-btn ng-star-inserted">
                      <a *ngIf="selected_language == 'eng'" href="#">Read More</a>
                        <a *ngIf="selected_language == 'spa'" href="#">Leer más</a>
                        <a *ngIf="selected_language == 'ger'" href="#">Mehr lesen</a>
                        <a *ngIf="selected_language == 'rus'" href="#">Читать далее</a>
                    </div>
                </div>
            </div>
                <!-- End single feature -->
            </div>
        </div>
    </div>
  </div><div class="transport_sec">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="transport_heading">
                    <h2 *ngIf="selected_language == 'eng'">Transport with Carisma Classic</h2>
                    <h2 *ngIf="selected_language == 'spa'">Transporte con Carisma Classic</h2>
                    <h2 *ngIf="selected_language == 'ger'">Transport mit Carisma Classic</h2>
                    <h2 *ngIf="selected_language == 'rus'">Транспорт с Carisma Classic</h2>
                    <p *ngIf="selected_language == 'eng'">We offer the transport service through Europe of your Classic Car.</p>
                    <p *ngIf="selected_language == 'spa'">Ofrecemos el servicio de transporte por Europa de su coche clásico.                  </p>
                    <p *ngIf="selected_language == 'ger'">Wir bieten den Transport Ihres Oldtimers durch Europa an.</p>
                    <p *ngIf="selected_language == 'rus'">Мы предлагаем услуги по транспортировке вашего классического автомобиля по Европе.
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="transport_sec">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="transport_heading">
                  <h2 *ngIf="selected_language == 'eng'">Sale on Commission</h2>
                  <h2 *ngIf="selected_language == 'spa'">Venta en comisión </h2>
                  <h2 *ngIf="selected_language == 'ger'">Verkauf auf Kommission </h2>
                  <h2 *ngIf="selected_language == 'rus'">Продажа на комиссионных началах</h2>
                  <p *ngIf="selected_language == 'eng'">Carisma Classic service to sell your classic car on Commission. We advertise the car in the internet, make pictures and prepare it for the german TUV.  </p>
                  <p *ngIf="selected_language == 'ger'">Carisma Classic Service zum Verkauf Ihres Oldtimers in Kommission. Wir inserieren das Auto im Internet, machen Bilder und bereiten es für den deutschen TÜV vor.  </p>
                  <p *ngIf="selected_language == 'spa'">Carisma Classic servicio para vender su coche clásico en la Comisión. Anunciamos el coche en internet, hacemos fotos y lo preparamos para el TUV alemán. </p>
                    <p *ngIf="selected_language == 'rus'">Carisma Classic - услуга по продаже вашего классического автомобиля на комиссию. Мы рекламируем автомобиль в интернете, делаем фотографии и готовим его к прохождению немецкого TUV.  </p>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="transport_sec">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="transport_heading">
                    <h2 *ngIf="selected_language == 'eng'">Classic Car Restoration </h2>
                    <h2 *ngIf="selected_language == 'spa'">Restauración de coches clásicos </h2>
                    <h2 *ngIf="selected_language == 'ger'">Oldtimer-Restaurierung </h2>
                    <h2 *ngIf="selected_language == 'rus'">Реставрация классических автомобилей </h2>
                    <p *ngIf="selected_language == 'eng'">Carisma Classic service to restore your classic car and bring it back to its glory. </p>
                      <p *ngIf="selected_language == 'spa'">Servicio de Carisma Classic para restaurar su coche clásico y devolverle su esplendor. </p>
                      <p *ngIf="selected_language == 'ger'">Carisma Classic Service, um Ihren Oldtimer zu restaurieren und ihm wieder zu seinem Glanz zu verhelfen.</p>
                      <p *ngIf="selected_language == 'rus'">Услуга Carisma Classic по восстановлению вашего классического автомобиля и возвращению ему его славы.</p>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="transport_sec">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="transport_heading">
                    <h2 *ngIf="selected_language == 'eng'">Classic Car Maintenance</h2>
                    <h2 *ngIf="selected_language == 'spa'">Mantenimiento de coches clásicos</h2>
                    <h2 *ngIf="selected_language == 'ger'">Oldtimer-Wartung</h2>
                    <h2 *ngIf="selected_language == 'rus'">Обслуживание классических автомобилей</h2>
                    <p *ngIf="selected_language == 'eng'">Carisma Classic offers to our customers the maintenance services for the classic cars. Regular maintenance, paint, tires and much more.</p>
                    <p *ngIf="selected_language == 'spa'">Carisma Classic ofrece a nuestros clientes los servicios de mantenimiento para los coches clásicos. Mantenimiento regular, pintura, neumáticos y mucho más.</p>
                    <p *ngIf="selected_language == 'ger'">Carisma Classic bietet unseren Kunden die Wartung der Oldtimer an. Regelmäßige Wartung, Lackierung, Reifen und vieles mehr.</p>
                    <p *ngIf="selected_language == 'rus'">Carisma Classic предлагает нашим клиентам услуги по техническому обслуживанию классических автомобилей. Регулярное техническое обслуживание, покраска, шины и многое другое.</p>
                </div>
            </div>
        </div>
    </div>