<header class="main-header">
    <nav class="navbar navbar-expand-lg navbar-light absoulte-top py-3" id="mainNav">
        <div class="container">
            <a class="navbar-brand js-scroll-trigger" [routerLink]="['/home']">
                <img *ngIf="router.url == '/' || router.url == '/home' || router.url == '/cars' || router.url == '/success_story';else footer_logo" [src]="header_img_url"  alt="" />
                <ng-template #footer_logo>
                    <img [src]="footer_img_url"  alt="" />    
                </ng-template>
            </a>
            <button class="navbar-toggler navbar-toggler-right custom-toggle" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ml-auto my-2 my-lg-0 text-white mr-3" *ngIf="header_menus.length > 0">
                    <li class="nav-item" *ngFor="let header_menu of header_menus;let menu_index=index;"><a class="nav-link js-scroll-trigger" routerLinkActive="active" [routerLink]="['/'+header_menu.redirection_url]">{{header_menu.menu_translation.title}}</a></li>
                </ul>
                <div class="pl-3" [ngClass]="(router.url == '/' || router.url == '/home' || router.url == '/cars' || router.url == '/success_story')?'langguage' : 'white-langguage'">
                  <select style="padding: 0px 5px 0px 5px;cursor: pointer" [ngClass]="(router.url == '/' || router.url == '/home' || router.url == '/cars' || router.url == '/success_story')?'head-select' : 'white-head-select'" (change)="change_language($event.target.value);">
                    <option value="eng" [selected]="selected_language == null || selected_language == 'eng'">English</option>
                    <option value="spa" [selected]="selected_language == 'spa'">Spanish</option>
                    <option value="ger" [selected]="selected_language == 'ger'">German</option>
                    <option value="rus" [selected]="selected_language == 'rus'">Russian</option>
                  </select>
                </div>
            </div>
        </div>
    </nav>
</header>