import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import{ LanguageService } from '../services/language.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-success-sory',
  templateUrl: './success-sory.component.html',
  styleUrls: ['./success-sory.component.css']
})
export class SuccessSoryComponent implements OnInit {

  selected_language = '';
  image_base_url = environment.image_base_url;
  success_story:any = [];
  story_translation:any = [];
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private LanguageService: LanguageService,
    private titleService: Title
  ){}

  ngOnInit(): void {
    this.titleService.setTitle("Success Story");
    this.LanguageService.currentMessage.subscribe(message => {
      this.selected_language = message;
      this.get_success_story();
    })
   
  }

  //function to get succss story content 
  get_success_story() {
    this.http.get<any>(environment.base_url+'/get_success_story').subscribe(data => {
      if(data.success) {
        this.success_story = data.data;
        console.log(this.success_story);
        this.story_translation = data.data.story_translation;
        this.spinner.hide();
      } 
      else {
        this.spinner.hide();
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")  
  }

}
