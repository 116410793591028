<div id="ourcarpage">
    <div class="pagetitleback" style="background-image: url('/assets/images/successtitle.jpg')">
      <div class="patitle" *ngIf="selected_language == null || selected_language == 'eng'">Our Cars</div>
      <div class="patitle" *ngIf="selected_language == 'spa'">Nuestros Coches</div>
      <div class="patitle" *ngIf="selected_language == 'ger'">Unsere Autos</div>
      <div class="patitle" *ngIf="selected_language == 'rus'">Наши автомобили</div>
    </div> 
  
    <section class="ourcar-list">
      <div class="container">
        <div class="row mt-5 mb-3">
          <div class="leftsearch col-12" *ngIf="brands_arr.length > 0">
            <h4 class="mt-4 mb-5" *ngIf="selected_language == null || selected_language == 'eng'">Detailed search</h4>
            <h4 class="mt-4 mb-5" *ngIf="selected_language == 'spa'">Busqueda detallada</h4>
            <h4 class="mt-4 mb-5" *ngIf="selected_language == 'ger'">Detaillierte Suche</h4>
            <h4 class="mt-4 mb-5" *ngIf="selected_language == 'rus'">Детальный поиск</h4>
            <div class="row mt-2 mb-2 d-flex align-items-center justify-content-center">
              <div class="col-xl-10 col-md-8 col-sm-12 col-12">
                <div class="row bold-text">
                  <div class="col-xl-3 col-md-6 col-6">
                    <h6 class="mt-2" *ngIf="selected_language == null || selected_language == 'eng'">SELECT CAR</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'spa'">SELECCIONAR COCHE</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'ger'">AUTO AUSWÄHLEN</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'rus'">ВЫБЕРИТЕ АВТО</h6>
                    <select class="custom-select" *ngIf="selected_language == null || selected_language == 'eng'" id="ingredients-first" (change)="get_filters($event.target.value, 'brand')">
                      <option value="0">Select brand</option>
                      <option value="{{brand.id}}" [selected]="brand_id == brand.id" *ngFor="let brand of brands_arr">{{brand.eng_title}}</option>
                    </select>
                    <select *ngIf="selected_language == 'spa'" class="custom-select" id="ingredients-first" (change)="get_filters($event.target.value, 'brand')">
                      <option value="0">Seleccionar marca</option>
                      <option value="{{brand.id}}" [selected]="brand_id == brand.id" *ngFor="let brand of brands_arr">{{brand.spa_title}}</option>
                    </select>
                    <select *ngIf="selected_language == 'ger'" class="custom-select" id="ingredients-first" (change)="get_filters($event.target.value, 'brand')">
                      <option value="0">Marke auswählen</option>
                      <option value="{{brand.id}}" [selected]="brand_id == brand.id" *ngFor="let brand of brands_arr">{{brand.ger_title}}</option>
                    </select>
                    <select *ngIf="selected_language == 'rus'" class="custom-select" id="ingredients-first" (change)="get_filters($event.target.value, 'brand')">
                      <option value="0">Выберите марку</option>
                      <option value="{{brand.id}}" [selected]="brand_id == brand.id" *ngFor="let brand of brands_arr">{{brand.rus_title}}</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-md-6 col-6">
                    <h6 class="mt-2" *ngIf="selected_language == null || selected_language == 'eng'">SELECT MODEL</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'spa'">SELECCIONAR MODELO</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'ger'">MODELL AUSWÄHLEN</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'rus'">ВЫБЕРИТЕ МОДЕЛЬ</h6>
                    <select *ngIf="selected_language == null || selected_language == 'eng'" class="custom-select" id="ingredients-second" (change)="get_filters($event.target.value, 'model')">
                      <option value="0">Select model</option>
                      <option [attr.bb]="model_id" value="{{car_model.id}}" [selected]="model_id == car_model.id" *ngFor="let car_model of car_models">{{car_model.eng_title}}</option>
                    </select>
                    <select *ngIf="selected_language == 'spa'" class="custom-select" id="ingredients-second" (change)="get_filters($event.target.value, 'model')">
                      <option value="0">Seleccionar modelo</option>
                      <option value="{{car_model.id}}" [selected]="model_id == car_model.id" *ngFor="let car_model of car_models">{{car_model.spa_title}}</option>
                    </select>
                    <select *ngIf="selected_language == 'ger'" class="custom-select" id="ingredients-second" (change)="get_filters($event.target.value, 'model')">
                      <option value="0">Modell auswählen</option>
                      <option value="{{car_model.id}}" [selected]="model_id == car_model.id" *ngFor="let car_model of car_models">{{car_model.ger_title}}</option>
                    </select>
                    <select *ngIf="selected_language == 'rus'" class="custom-select" id="ingredients-second" (change)="get_filters($event.target.value, 'model')">
                      <option value="0">Выбрать модель</option>
                      <option value="{{car_model.id}}" [selected]="model_id == car_model.id" *ngFor="let car_model of car_models">{{car_model.rus_title}}</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-md-6 col-6">
                    <h6 class="mt-2" *ngIf="selected_language == null || selected_language == 'eng'">SELECT YEARS</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'spa'">SELECCIONAR AÑOS</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'ger'">JAHRE AUSWÄHLEN</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'rus'">ВЫБЕРИТЕ ГОДЫ</h6>
                    <select class="custom-select" id="ingredients-third" (change)="get_filters($event.target.value, 'year')">
                      <option value="0" *ngIf="selected_language == null || selected_language == 'eng'">Select registration year</option>
                      <option value="0" *ngIf="selected_language == 'spa'">Seleccionar año de registro</option>
                      <option value="0" *ngIf="selected_language == 'ger'">Registrierungsjahr auswählen</option>
                      <option value="0" *ngIf="selected_language == 'rus'">Выберите год регистрации</option>
                      <option value="{{registration_year.registration_year}}" [selected]="selected_registration_year == registration_year.registration_year" *ngFor="let registration_year of registration_years">{{registration_year.registration_year}}</option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-md-6 col-6">
                    <h6 class="mt-2" *ngIf="selected_language == null || selected_language == 'eng'">PRICE UNTIL</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'spa'">PRECIO HASTA</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'ger'">PREIS BIS</h6>
                    <h6 class="mt-2" *ngIf="selected_language == 'rus'">ЦЕНА ДО</h6>
                    <select class="custom-select" id="ingredients-fourth">
                      <option value="0" *ngIf="selected_language == null || selected_language == 'eng'">Select price</option>
                      <option value="0" *ngIf="selected_language == 'spa'">Seleccionar precio</option>
                      <option value="0" *ngIf="selected_language == 'ger'">Preis auswählen</option>
                      <option value="0" *ngIf="selected_language == 'rus'">Выберите цену</option>
                      <option value="{{price.price}}" [selected]="selected_price == price.price" *ngFor="let price of search_filter_arr.prices">{{price.price}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-2 col-md-4 col-sm-12 col-12">
                <div class="row">
                    <div class="col-12 mt-2">
                      <a href="javascript:;" class="btn custom-btn" (click)="search_car();" *ngIf="selected_language == null || selected_language == 'eng'">Search Car</a>
                      <a href="javascript:;" class="btn custom-btn" (click)="search_car();" *ngIf="selected_language == 'spa'">Buscar coche</a>
                      <a href="javascript:;" class="btn custom-btn" (click)="search_car();" *ngIf="selected_language == 'ger'">Auto suchen</a>
                      <a href="javascript:;" class="btn custom-btn" (click)="search_car();" *ngIf="selected_language == 'rus'">Поиск автомобиля</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
	   <div class="container">
          <div class="row" *ngIf="cars_arr.length > 0;else no_car_found">
            <div class="col-xl-12 col-lg-12 col-sm-12 col-12">
              <div class="d-flex justify-content-end mb-4" *ngIf="selected_language == 'eng'">
                <div class="btn-group list-btns">
                    <button (click)="manage_view('list');" id="btn-list" type="button" class="btn"><img src="/assets/images/list.png" alt="">
                        List
                    </button>
                    <button (click)="manage_view('grid');" id="btn-grid" type="button" class="btn"><img src="/assets/images/grid.png" alt="">
                        Grid
                    </button>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4" *ngIf="selected_language == 'spa'">
                <div class="btn-group list-btns">
                    <button (click)="manage_view('list');" id="btn-list" type="button" class="btn"><img src="/assets/images/list.png" alt="">
                      Lista
                    </button>
                    <button (click)="manage_view('grid');" id="btn-grid" type="button" class="btn"><img src="/assets/images/grid.png" alt="">
                      Parrila
                    </button>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4" *ngIf="selected_language == 'ger'">
                <div class="btn-group list-btns">
                    <button (click)="manage_view('list');" id="btn-list" type="button" class="btn"><img src="/assets/images/list.png" alt="">
                      aufführen
                    </button>
                    <button (click)="manage_view('grid');" id="btn-grid" type="button" class="btn"><img src="/assets/images/grid.png" alt="">
                      Gitter
                    </button>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4" *ngIf="selected_language == 'rus'">
                <div class="btn-group list-btns">
                    <button (click)="manage_view('list');" id="btn-list" type="button" class="btn"><img src="/assets/images/list.png" alt="">
                      список
                    </button>
                    <button (click)="manage_view('grid');" id="btn-grid" type="button" class="btn"><img src="/assets/images/grid.png" alt="">
                      Сетка
                    </button>
                </div>
              </div>
              <div class="list-view" id="list-view" style="display: block;">
			  
                <div class="productgrid-list" *ngFor="let car of cars_arr; let car_index=index;">
                    <div class="row">
                      <div class="col-lg-4 col-sm-12 col-12 col-4 mb-3">

                        <a>
                          <div class="pro-img-left">
                              <div id="demo{{car_index}}" class="carousel slide" data-ride="carousel">
                                <!-- The slideshow -->
                                <div class="carousel-inner" *ngIf="car.car_images.length > 0">
                                  <div class="carousel-item" [class] = "car_image_index == 0 ? 'active' : ''" *ngFor="let car_image of car.car_images; let car_image_index=index">
                                    <img [src]="image_base_url+'/car_images/'+car.id+'/'+car_image.img">
                                  </div>
                                </div>
    
                                <!-- Left and right controls -->
                                <a class="carousel-control-prev" href="#demo{{car_index}}" data-slide="prev">
                                  <span class="carousel-control-prev-icon"></span>
                                </a>
                                <a class="carousel-control-next" href="#demo{{car_index}}" data-slide="next">
                                  <span class="carousel-control-next-icon"></span>
                                </a>
                            </div>
                            <div class="viewmore">
                              <span>{{car.car_images.length}} +</span>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="col-lg-8 col-8">
                        <div class="pro-des-right">
                          <div class="row">
                            <div class="col-md-7 col-sm-12 col-12">
                              <a [routerLink]="[car.car_brands.slug+'/'+car.slug]"><h4 *ngIf="selected_language == null || selected_language == 'eng'">{{car.car_brands.eng_title}}-{{car.car_models.eng_title}} / {{car.eng_title}}</h4></a>
							  <a [routerLink]="[car.car_brands.slug+'/'+car.slug]"><h4 *ngIf="selected_language == 'spa'">{{car.car_brands.spa_title}}-{{car.car_models.spa_title}} / {{car.spa_title}}</h4></a>
                              <a [routerLink]="[car.car_brands.slug+'/'+car.slug]"><h4 *ngIf="selected_language == 'ger'">{{car.ger_title}}-{{car.car_models.ger_title}} / {{car.ger_title}}</h4></a>
                              <a [routerLink]="[car.car_brands.slug+'/'+car.slug]"><h4 *ngIf="selected_language == 'rus'">{{car.rus_title}}-{{car.car_models.rus_title}} / {{car.rus_title}}</h4></a>
                            </div>
                            <div class="col-md-5 col-sm-12 col-12 text-lg-right text-sm-left">
                              <h4>{{car.price}} €</h4>
                              <!-- <a href="#">Finanzierung berechnen</a> -->
                            </div>
                            <div class="col-12">
                              <p>
                                <span *ngIf="selected_language == null || selected_language == 'eng'">
                                  year: {{car.year | date:'d MMM, y'}},
                                  color: {{car.average}},
                                </span>
                                <span *ngIf="selected_language == 'spa'">
                                  año: {{car.year | date:'d MMM, y'}},
                                  kilometraje: {{car.average}},
                                </span>
                                <span *ngIf="selected_language == 'ger'">
                                  Jahr: {{car.year | date:'d MMM, y'}},
                                  Kilometerstand: {{car.average}},
                                </span>
                                <span *ngIf="selected_language == 'rus'">
                                  год: {{car.year | date:'d MMM, y'}},
                                  пробег: {{car.average}},
                                </span>
                                <span *ngIf="selected_language == null || selected_language == 'eng'">
                                  kilometer: {{car.km}},
                                  fuel type: {{car.fuel_type}},
                                </span>
                                <span *ngIf="selected_language == 'spa'">
                                  <span *ngIf="car.fuel_type == 'petrol';else spa_diesel">
                                    kilómetro: {{car.km}},
                                    tipo de combustible: gasolina,
                                  </span>
                                  <ng-template #spa_diesel>
                                    kilómetro: {{car.km}},
                                    tipo de combustible: diesel,
                                  </ng-template>
                                </span>
                                <span *ngIf="selected_language == 'ger'">
                                  <span *ngIf="car.fuel_type == 'petrol';else ger_diesel">
                                    Kilometer: {{car.km}},
                                    Treibstoffart: Benzin,
                                  </span>
                                  <ng-template #ger_diesel>
                                    Kilometer: {{car.km}},
                                    Treibstoffart: Diesel-,
                                  </ng-template>
                                </span>
                                <span *ngIf="selected_language == 'rus'">
                                  <span *ngIf="car.fuel_type == 'petrol';else rus_diesel">
                                    километр: {{car.km}},
                                    тип топлива: бензин,
                                  </span>
                                  <ng-template #rus_diesel>
                                    километр: {{car.km}},
                                    тип топлива: дизель,
                                  </ng-template>
                                </span>
                                <span class="d-inline badge badge-success" [ngClass]="{'status-sold': environment.car_status[car.car_status]['eng'] == 'Sold'}">{{environment.car_status[car.car_status][selected_language]}}</span>
                              </p>
                              <!-- <p>Kombi, Autogas (LPG), Schaltgetriebe</p> -->
                              <!-- <p>DE-01728 Welschhufe, Privatanbieter, manual transmission</p> -->
                            </div>
                            <div class="col-md-4 col-sm-12 col-12 mt-4">
                              <!-- <span><a href="">Versicherung</a>, &nbsp; &nbsp;<a href="">Leasing</a></span> -->
                            </div>
                            <div class="col-md-8 col-sm-12 col-12 text-lg-right text-sm-left mt-4" *ngIf="selected_language == null || selected_language == 'eng'">
                              <a [routerLink]="['/contact']" class="contact">Contact</a> 
                              <a [routerLink]="['/contact']" class="park">Park</a>
                            </div>
                            <div class="col-md-8 col-sm-12 col-12 text-lg-right text-sm-left mt-4" *ngIf="selected_language == 'spa'">
                              <a [routerLink]="['/contact']" class="contact">Contacto</a> 
                              <a [routerLink]="['/contact']" class="park">Parque</a>
                            </div>
                            <div class="col-md-8 col-sm-12 col-12 text-lg-right text-sm-left mt-4" *ngIf="selected_language == 'ger'">
                              <a [routerLink]="['/contact']" class="contact">Kontakt</a> 
                              <a [routerLink]="['/contact']" class="park">Park</a>
                            </div>
                            <div class="col-md-8 col-sm-12 col-12 text-lg-right text-sm-left mt-4" *ngIf="selected_language == 'rus'">
                              <a [routerLink]="['/contact']" class="contact">Связаться с нами</a> 
                              <a [routerLink]="['/contact']" class="park">Парк</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
				
              </div>
              <div class="grid-view" id="grid-view" style="display: none;">
                <div class="productgrid-list">
                    <div class="row">
                        <div class="col-xl-4 col-md-6 col-sm-12 col-12 mb-3" *ngFor="let car of cars_arr; let car_index=index;">
                            <div class="pro-img-left">
                                <div id="demo_{{car_index}}" class="carousel slide" data-ride="carousel">
                                    <!-- The slideshow -->
                                    <div class="carousel-inner" *ngIf="car.car_images.length > 0">
                                        <div class="carousel-item" [class] = "car_image_index == 0 ? 'active' : ''" *ngFor="let car_image of car.car_images; let car_image_index=index">
                                            <img [src]="image_base_url+'/car_images/'+car.id+'/'+car_image.img" alt="">
                                        </div>
                                    </div>

                                    <!-- Left and right controls -->
                                    <a class="carousel-control-prev" href="#demo_{{car_index}}" data-slide="prev">
                                        <span class="carousel-control-prev-icon"></span>
                                    </a>
                                    <a class="carousel-control-next" href="#demo_{{car_index}}" data-slide="next">
                                        <span class="carousel-control-next-icon"></span>
                                    </a>
                                </div>
                                <div class="viewmore">
                                  <span>{{car.car_images.length}} +</span>
                                </div>
                            </div>
                            <div class="pro-des-right">
                                <div class="row mt-3">
                                     <div class="col-md-12 col-sm-12 col-12">
                                      <a [routerLink]="[car.car_brands.slug+'/'+car.slug]"><h4 *ngIf="selected_language == null || selected_language == 'eng'">{{car.car_brands.eng_title}}-{{car.car_models.eng_title}} / {{car.eng_title}}</h4></a>
                        <a [routerLink]="[car.car_brands.slug+'/'+car.slug]"><h4 *ngIf="selected_language == 'spa'">{{car.car_brands.spa_title}}-{{car.car_models.spa_title}} / {{car.spa_title}}</h4></a>
                                      <a [routerLink]="[car.car_brands.slug+'/'+car.slug]"><h4 *ngIf="selected_language == 'ger'">{{car.ger_title}}-{{car.car_models.ger_title}} / {{car.ger_title}}</h4></a>
                                      <a [routerLink]="[car.car_brands.slug+'/'+car.slug]"><h4 *ngIf="selected_language == 'rus'">{{car.rus_title}}-{{car.car_models.rus_title}} / {{car.rus_title}}</h4></a>
                                    </div>
                                    
                                    <div class="col-12">
                                      <p>
                                        <span *ngIf="selected_language == null || selected_language == 'eng'">
                                          year: {{car.year | date:'d MMM, y'}},
                                          color: {{car.average}},
                                        </span>
                                        <span *ngIf="selected_language == 'spa'">
                                          año: {{car.year | date:'d MMM, y'}},
                                          kilometraje: {{car.average}},
                                        </span>
                                        <span *ngIf="selected_language == 'ger'">
                                          Jahr: {{car.year | date:'d MMM, y'}},
                                          Kilometerstand: {{car.average}},
                                        </span>
                                        <span *ngIf="selected_language == 'rus'">
                                          год: {{car.year | date:'d MMM, y'}},
                                          пробег: {{car.average}},
                                        </span>
                                        <span *ngIf="selected_language == null || selected_language == 'eng'">
                                          kilometer: {{car.km}},
                                          fuel type: {{car.fuel_type}},
                                        </span>
                                        <span *ngIf="selected_language == 'spa'">
                                          <span *ngIf="car.fuel_type == 'petrol';else spa_diesel">
                                            kilómetro: {{car.km}},
                                            tipo de combustible: gasolina,
                                          </span>
                                          <ng-template #spa_diesel>
                                            kilómetro: {{car.km}},
                                            tipo de combustible: diesel,
                                          </ng-template>
                                        </span>
                                        <span *ngIf="selected_language == 'ger'">
                                          <span *ngIf="car.fuel_type == 'petrol';else ger_diesel">
                                            Kilometer: {{car.km}},
                                            Treibstoffart: Benzin,
                                          </span>
                                          <ng-template #ger_diesel>
                                            Kilometer: {{car.km}},
                                            Treibstoffart: Diesel-,
                                          </ng-template>
                                        </span>
                                        <span *ngIf="selected_language == 'rus'">
                                          <span *ngIf="car.fuel_type == 'petrol';else rus_diesel">
                                            километр: {{car.km}},
                                            тип топлива: бензин,
                                          </span>
                                          <ng-template #rus_diesel>
                                            километр: {{car.km}},
                                            тип топлива: дизель,
                                          </ng-template>
                                        </span>
                                      </p>
                                      
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-12 text-sm-left mt-3 mb-3">
                                      <span class="d-inline badge badge-success p-2" [ngClass]="{'status-sold': environment.car_status[car.car_status]['eng'] == 'Sold'}">{{environment.car_status[car.car_status][selected_language]}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12 col-12 text-lg-right text-sm-left mt-3 mb-3">
                                    <h4>{{car.price}} €</h4>
                                </div>

                                    <div class="col-6 mt-2 d-flex justify-content-start">
                                        <a *ngIf="selected_language == null || selected_language == 'eng'" [routerLink]="['/contact']" class="contact">Contact</a>
                                        <a *ngIf="selected_language == 'spa'" [routerLink]="['/contact']" class="contact">Contacto</a> 
                                        <a *ngIf="selected_language == 'ger'" [routerLink]="['/contact']" class="contact">Kontakt</a>
                                        <a *ngIf="selected_language == 'rus'" [routerLink]="['/contact']" class="contact">Связаться с нами</a>
                                    </div>
                                    <div class="col-6 mt-2 d-flex justify-content-end">
                                        <a *ngIf="selected_language == null || selected_language == 'eng'" [routerLink]="['/contact']" class="park">Park</a>
                                        <a *ngIf="selected_language == 'spa'" [routerLink]="['/contact']" class="park">Parque</a>
                                        <a *ngIf="selected_language == 'ger'" [routerLink]="['/contact']" class="park">Park</a>
                                        <a *ngIf="selected_language == 'rus'" [routerLink]="['/contact']" class="park">Парк</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              <div class="row mt-5 mb-3" *ngIf="pagination != null">
                <div class="col-12">
                  <nav class="mt-5">
                    <ul class="pagination">
                      <!-- <li class="page-item">
                        <a class="page-link" href="#!" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span class="sr-only">Previous</span>
                        </a>
                      </li> -->
                      <li class="page-item" [class]="{'active': pagination_count.page == current_page}" *ngFor="let pagination_count of pagination_arr;">
                        <a class="page-link" (click)="get_cars_list(pagination_count.page);">{{pagination_count.page}}</a>
                      </li>
                      <!-- <li class="page-item">
                        <a class="page-link" href="#!" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li> -->
                    </ul>
                  </nav>
                </div>
              </div>  
            </div>
          </div>
          
          <ng-template #no_car_found class="row mt-5 mb-5" style="text-align:center; margin: 0 auto;"> 
          </ng-template>   
          <div *ngIf= "cars_arr.length <= 0">
              <div  class="row mt-5 mb-5" style="font-size:50px;text-align:center;margin-top:50px;display: block;" *ngIf=
            "selected_language == 'eng'">
              No car found.
            </div>
         
            <div  class="row mt-5 mb-5" style="font-size:50px;text-align:center;margin-top:50px;display: block;" *ngIf=
            "selected_language == 'ger'">
            Kein Auto gefunden. </div>
         
          
            <div  class="row mt-5 mb-5" style="font-size:50px;text-align:center;margin-top:50px;display: block;" *ngIf=
            "selected_language == 'spa'">
            No se encontró coche. </div>
        
          
            <div  class="row mt-5 mb-5" style="font-size:50px;text-align:center;margin-top:50px;display: block;" *ngIf=
            "selected_language == 'rus'">
            Машин не найдено.
          </div>
          </div>
         
          </div>
        </div>
        
      </div>
    </section>
  </div>