  
<div class="pagetitleback">
    <div class="patitle" *ngIf="selected_language == null || selected_language == 'eng'">Success story</div>
    <div class="patitle" *ngIf="selected_language == 'spa'">Historia exitosa</div>
    <div class="patitle" *ngIf="selected_language == 'ger'">Erfolgsgeschichte</div>
    <div class="patitle" *ngIf="selected_language == 'rus'">История успеха</div>
</div>

 <section class="successstorypage">
    <div class="container">
      <div class="row mt-4">
        <div class="col-12 mt-5 mb-2">
            <h6>{{story_translation.title}}</h6>
        </div>
        <div class="col-xl-12 col-lg-12 col-sm-12 col-12">
            <p>
                {{story_translation.description}}
            </p>
        </div>
    </div>
    </div>
</section>  

<section class="carmadrid" style="background-image: url({{image_base_url}}/success_story_images/{{success_story.bg_img}});">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
            <h3 class="text-white">{{story_translation.parallax_title}} <span>{{story_translation.parallax_inverted_title}}</span></h3>
            <p class="description text-white mt-5">{{story_translation.parallax_description}}</p>
            <img class="mt-2" [src]="image_base_url+'/success_story_images/'+success_story.author_img" alt="">
            <p class="author text-white mt-3">{{story_translation.author_name}}</p>
            <a class="btn text-white mt-4" href="#">{{story_translation.btn_title}}</a>
        </div>
      </div>
    </div>
</section>


<section class="stillin">
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="col-12 mt-2">
        <h2>Still in doubt</h2>
      </div>
    </div>
    <div class="row mt-5 d-flex">
      <div class="col-xl-6 col-md-6 col-12 pr-md-0 pr-sm-1">
        <div class="story-img"></div>
      </div>
      <div class="col-xl-6 col-md-6 col-12 pl-md-0 pl-sm-1">
          <div class="still-info">
            <h4>Lost  nothing, only benefits <span>Free car</span></h4>
            <p class="ulhead">Saved:</p>
            <ul>
              <li>Time</li>
               <li>Money</li>
               <li>Nerves</li>
            </ul>
          </div>
      </div>
    </div>

    <div class="row mt-5 pt-4 d-flex">
      <div class="col-xl-6 col-md-6 col-12 pr-md-0 pr-sm-1">
        <div class="still-info">
          <h6 class="pt-4 pb-3">Roadster in new car condition</h6>
          <p>With 326 hp and 480 Nm, the sedan is in the ancestral series with the W 109 280 SEL 6.3 and W 116 450 SEL 6.9: a sedan with the performance of a sports car.Visually, the 500 E can only be seen as a second glance: discreetly widened fenders, integrated in the front apron fog lights and 16-inch alloy wheels in 8-hole design are its external distinguishing features.</p>
          <p>ALL TIME STARS is the vehicle trade of Mercedes-Benz Classic. With the claim of the manufacturer, we offer selected young and vintage cars for sale.</p>
          <p>Whether fully restored 300 SL Roadster in new car condition or "dash-8" sedan with the traces of everyday life, they have one thing  in common: 12 months warranty - the quality seal of ALL TIME STARS.</p>
        </div>
      </div>
      <div class="col-xl-6 col-md-6 col-12 pl-md-0 pl-sm-1">
        <div class="story-img"></div>
      </div>
    </div>


    <div class="row mt-5 pt-4 mb-5 d-flex">
      <div class="col-xl-6 col-md-6 col-12 pr-md-0 pr-sm-1">
        <div class="story-img"></div>
      </div>
      <div class="col-xl-6 col-md-6 col-12 pl-md-0 pl-sm-1">
          <div class="still-info">
            <h4>Lost  nothing, only benefits <span>Free car</span></h4>
            <p>With 326 hp and 480 Nm, the sedan is in the ancestral series with the W 109 280 SEL 6.3 and W 116 450 SEL 6.9: a sedan with the performance of a sports car.Visually, the 500 E can only be seen as a second glance: discreetly widened fenders, integrated in the front apron fog lights and 16-inch alloy wheels in 8-hole design are its external distinguishing features.</p>
            <p>ALL TIME STARS is the vehicle trade of Mercedes-Benz Classic. With the claim of the manufacturer, we offer selected young and vintage cars for sale.</p>
          </div>
      </div>
    </div>

    <div class="row mt-5 pt-4 d-flex">
      <div class="col-xl-6 col-md-6 col-12 pr-md-0 pr-sm-1">
        <div class="still-info">
          <h6 class="pt-4 pb-3">Roadster in new car condition</h6>
          <p>With 326 hp and 480 Nm, the sedan is in the ancestral series with the W 109 280 SEL 6.3 and W 116 450 SEL 6.9: a sedan with the performance of a sports car.Visually, the 500 E can only be seen as a second glance: discreetly widened fenders, integrated in the front apron fog lights and 16-inch alloy wheels in 8-hole design are its external distinguishing features.</p>
          <p>ALL TIME STARS is the vehicle trade of Mercedes-Benz Classic. With the claim of the manufacturer, we offer selected young and vintage cars for sale.</p>
          <p>Whether fully restored 300 SL Roadster in new car condition or "dash-8" sedan with the traces of everyday life, they have one thing  in common: 12 months warranty - the quality seal of ALL TIME STARS.</p>
        </div>
      </div>
      <div class="col-xl-6 col-md-6 col-12 pl-md-0 pl-sm-1">
        <div class="story-img"></div>
      </div>
    </div>
    <div class="row mt-5 text-center">
      <div class="col-12">
        <a class="btn" href="#">Lets Buy</a>
      </div>
    </div>
  </div>
</section>
