import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import{ LanguageService } from '../../services/language.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  footer_menus;
  footer_img_url = '';
  selected_language ='';
  routerUrl = '';
  
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    private LanguageService: LanguageService,
    private spinner: NgxSpinnerService
  ) { }
  get routeUrl(): any {
		return  this.router.url;
	}
  ngOnInit(): void {
     
    this.LanguageService.currentMessage.subscribe(message => {
      this.get_menus_list();
      this.selected_language = message;
      
    })
  }

  get year(): any {
    return new Date().getFullYear();
  }
  
  get_menus_list() {
    this.http.get<any>(environment.base_url+'/get_footer_menu').subscribe(data => {
      if(data.success) {
        console.log(data)
        this.footer_menus = data.data.menus_arr;
        this.footer_img_url = environment.image_base_url+'/'+data.data.logo.type+'/'+data.data.logo.img;
        this.spinner.hide();
      } 
      else {
        this.toastr.error(data.msg.text, "")  
        this.spinner.hide();
      }
    }),
    (error) => {this.toastr.error(error.message, ""); this.spinner.hide(); }
    
  }

}
