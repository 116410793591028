import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import{ LanguageService } from '../services/language.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  sliders = [];
  why_us_btns_arr:any = [];
  testimonials_arr:any = [];
  testimonial_main_title:any = [];
  parallax:any = [];
  parallax_img:any = [];
  environment = environment;
  selected_language = '';
  search_filter_arr:any = [];
  brands_arr = [];
  car_models = [];
  registration_years = [];
  brand_id = 0;
  model_id = 0;
  registration_year = 0;
  blogs = [];
  choose_car_arr = [];
  customOptions: OwlOptions = {
    autoplay: true,
    lazyLoad: true,
    loop: true,
    margin: 20,
    dots: false,
    //responsiveClass: true,
    autoHeight: true,
    autoWidth: false,
    smartSpeed: 2000,
    nav: false,
    
    navText: ['Next', 'Prev'],
    responsive: {
      0: {
        items: 1
      },
  
      600: {
        items: 2
      },
  
      1024: {
        items: 3
      },
  
      1366: {
        items: 3
      }
    }
  }
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private LanguageService: LanguageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Home");
    this.LanguageService.currentMessage.subscribe(message => {
      this.selected_language = message;
      this.get_home_page_data();
    })
    localStorage.removeItem('searchCar');

    // Calling common functions to get search filters array
    this.get_filters('', '');
  }

  // Function to get home page data
  get_home_page_data() {
    this.http.get<any>(environment.base_url+'/get_home_page_data').subscribe(data => {
      if(data.success) {
        //console.log(data);
        this.sliders = data.data.sliders;
        this.why_us_btns_arr = data.data.why_us_btns_arr;
        this.testimonials_arr = data.data.testimonials_arr;
        this.testimonial_main_title = data.data.testimonial_main_title;
        this.parallax_img = environment.image_base_url+'/parallax_bg_img/'+data.data.parallax_section.id+'/'+data.data.parallax_section.img;
        this.parallax = data.data.parallax_section.parallax_section_translation;
        this.blogs = data.data.blogs;
        //console.log(this.blogs);
        this.choose_car(0)
        this.spinner.hide();
      } 
      else {
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

  //Function to get filters array
  get_filters(value, type) {

    if(type == 'brand') {
      this.brand_id = value;
      this.model_id = 0;
      this.registration_year = 0
    }

    if(type == 'model') {
      this.model_id = value;
      this.registration_year = 0
    }

    if(type == 'year') {
      this.registration_year = value;
    }

    this.http.post<any>(environment.base_url+'/get_search_filters', {
      brand_id: this.brand_id,
      model_id: this.model_id,
      registration_year: this.registration_year,  
    }).subscribe(data => {
      if(data.success) {
        //console.log(data);
        if(data.data.search_filter_arr.car_brands != undefined) {
          this.brands_arr = data.data.search_filter_arr.car_brands;
        }

        if(data.data.search_filter_arr.car_models != undefined) {
          this.car_models = data.data.search_filter_arr.car_models;
        }

        if(data.data.search_filter_arr.registration_years != undefined) {
          this.registration_years = data.data.search_filter_arr.registration_years;
        }
        this.search_filter_arr = data.data.search_filter_arr;  
        this.spinner.hide();
      } 
      else {
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")

  }

  search_car() {
    let search_car_arr = {
      "car_brand_id": "",
      "car_model_id": "",
      "registration_year": "",
      "price": "",   
    };
    let selected_filters = {
      "brands_arr": this.brands_arr,
      "car_models": this.car_models,
      "registration_years": this.registration_years
    };
    var car_brand_id:any = document.getElementById("ingredients-first");
    search_car_arr.car_brand_id = car_brand_id.value;

    var car_model_id:any = document.getElementById("ingredients-second");
    search_car_arr.car_model_id = car_model_id.value;

    var registration_year:any = document.getElementById("ingredients-third");
    search_car_arr.registration_year = registration_year.value;

    var price:any = document.getElementById("ingredients-fourth");
    search_car_arr.price = price.value;    

    localStorage.setItem('searchCar', JSON.stringify(search_car_arr));
    localStorage.setItem('selected_filters', JSON.stringify(selected_filters));
    this.router.navigateByUrl('/cars');
  }

  // Function to get cars for "Choose your car section"
  choose_car(car_status_id) {
    this.http.get<any>(environment.base_url+'/get_choose_car_data/'+car_status_id).subscribe(data => {
      if(data.success) {
        this.choose_car_arr = data.data;
        this.spinner.hide();
      } 
      else {
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")  
  }

}
