import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CarsComponent } from './cars/cars/cars.component';
import { CarComponent } from './cars/car/car.component';
import { ContactComponent } from './contact/contact.component';
import { InvestorsComponent } from './investors/investors.component';
import { BlogsComponent } from './blogs/blogs.component';
import { SuccessSoryComponent } from './success-sory/success-sory.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServiceComponent } from './service/service.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'cars', component: CarsComponent },
    { path: 'cars/:brand/:slug', component: CarComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'investors', component: InvestorsComponent },
    { path: 'blog/:id', component: BlogsComponent },
    { path: 'success_story', component: SuccessSoryComponent },
    { path: 'service', component: ServiceComponent },
    {path: '404', component: NotFoundComponent},
    {path: '**', redirectTo: '/404'}
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes) 
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
