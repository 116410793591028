import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{ LanguageService } from '../../services/language.service';
import { NgxSpinnerService } from "ngx-spinner";
import {ActivatedRoute, Router} from '@angular/router';
import { Title } from '@angular/platform-browser';
import jspdf from 'jspdf';  
import html2canvas from 'html2canvas';  
@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.css']
})
export class CarComponent implements OnInit {

  car:any = [];
  selected_car_brand:any = [];
  related_cars:any = [];
  selected_car_model:any = [];
  car_images:any = [];
  car_feature_image:any = [];
  car_details:any = [];
  car_technical_details:any = [];
  car_individual_config_details:any = [];
  selected_language = '';
  image_base_url = environment.image_base_url;
  car_brands = [];
  search_filter_arr:any = [];
  brands_arr = [];
  car_models = [];
  registration_years = [];
  brand_id = 0;
  model_id = 0;
  registration_year = 0;
  car_id = '';
  car_url = window.location.href;
  car_details_count = 0;
  technical_details_count = 0;
  individual_details_count = 0;
  car_images_count = 0;
  pdf_images = [];
  view_img_url = '';
  pSlug = '';
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private LanguageService: LanguageService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Car");
    this.LanguageService.currentMessage.subscribe(message => {
      this.selected_language = message;
      // Calling common functions to get search filters array
      this.get_filters('', '');
    })

    this.route.params.subscribe(params => {
      this.car_id = params['id'];
    });

    this.route.params.subscribe(params => {
      this.car_id = params['brand'];
      this.pSlug = params['slug'];
    });

    this.http.get<any>(environment.base_url+'/get_car_detail/'+this.car_id+'/'+this.pSlug).subscribe(data => {
      if(data.success) {
        //console.log(this.car);
        this.spinner.hide();
        if(data.data == null) {
          this.toastr.error("Car not found.", "")  
        }
        else {
          this.car = data.data.car;
          this.selected_car_brand = data.data.car.car_brands;
          this.selected_car_model = data.data.car.car_models;
          this.car_images = data.data.car.car_images;
          this.car_feature_image = data.data.car.car_feature_image;
          this.car_details = data.data.car.car_details;
          this.car_technical_details = data.data.car.car_technical_details;
          this.car_individual_config_details = data.data.car.car_individual_config_details;
          this.related_cars = data.data.related_cars;
          

          this.car_details_count = Math.ceil(data.data.car.car_details.length / 2);
          this.technical_details_count = Math.ceil(data.data.car.car_details.length / 2);
          this.individual_details_count = Math.ceil(data.data.car.car_details.length / 2);
          let cube_of_images= [];
          let car_arr_length:any = this.car_images.length - 1;
          if(this.car_images.length > 0) {
            for(let i in this.car_images) {
              if(cube_of_images.length < 3) {
                cube_of_images.push(this.car_images[i]);
              }
              else {
                this.pdf_images.push(cube_of_images);
                cube_of_images = [];
                cube_of_images.push(this.car_images[i]);
              }
              
              if(i == car_arr_length && cube_of_images.length > 0) {
                this.pdf_images.push(cube_of_images);
              }
            }
          }
        }
      } 
      else {
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "") 

  }

  //Function to get filters array
  get_filters(value, type) {

    localStorage.removeItem('searchCar');
    
    if(type == 'brand') {
      this.brand_id = value;
      this.model_id = 0;
      this.registration_year = 0
    }

    if(type == 'model') {
      this.model_id = value;
      this.registration_year = 0
    }

    if(type == 'year') {
      this.registration_year = value;
    }

    this.http.post<any>(environment.base_url+'/get_search_filters', {
      brand_id: this.brand_id,
      model_id: this.model_id,
      registration_year: this.registration_year,  
    }).subscribe(data => {
      if(data.success) {
        if(data.data.search_filter_arr.car_brands != undefined) {
          this.brands_arr = data.data.search_filter_arr.car_brands;
        }

        if(data.data.search_filter_arr.car_models != undefined) {
          this.car_models = data.data.search_filter_arr.car_models;
        }

        if(data.data.search_filter_arr.registration_years != undefined) {
          this.registration_years = data.data.search_filter_arr.registration_years;
        }
        this.search_filter_arr = data.data.search_filter_arr;  
        this.spinner.hide();
      } 
      else {
        this.toastr.error(data.msg.text, "")  
      }
    }),
    (error) => this.toastr.error(error.message, "")

  }

  search_car() {
    let search_car_arr = {
      "car_brand_id": "",
      "car_model_id": "",
      "registration_year": "",
      "price": "",   
    };
    var car_brand_id:any = document.getElementById("ingredients-first");
    search_car_arr.car_brand_id = car_brand_id.value;

    var car_model_id:any = document.getElementById("ingredients-second");
    search_car_arr.car_model_id = car_model_id.value;

    var registration_year:any = document.getElementById("ingredients-third");
    search_car_arr.registration_year = registration_year.value;

    var price:any = document.getElementById("ingredients-fourth");
    search_car_arr.price = price.value;

    let serch_car_data = JSON.parse(localStorage.getItem('searchCar'));
    
    localStorage.setItem('searchCar', JSON.stringify(search_car_arr));
    this.router.navigateByUrl('/cars');
  }


  show_all_pictures(image_index) {
    if(image_index == 5) {
      var load_more_div:any = document.getElementById('load_more_div');
      load_more_div.style.display = "none";
      var hide_car_images:any = document.getElementsByClassName('hide_car_images');
      for ( var i = 0; i < hide_car_images.length; i++ ) {
        hide_car_images[i].style.display = 'block';
      }
    }
    else {
      return;
    }
  }

  download_pdf() {
    if(confirm('Are you sure to download the PDF?')) {
      this.toastr.success("PDF file is downloading, Please wait.", "")
    
      var data = document.getElementById('car-pdf');  //Id of the table
      data.style.display = "block";
      html2canvas(data,{allowTaint:true, useCORS:true}).then(function(canvas) {
        var HTML_Width = canvas.width
        var HTML_Height = canvas.height
        var top_left_margin = 15;
        var PDF_Width:any = HTML_Width+(top_left_margin*2);
        var PDF_Height:any = (PDF_Width*1.5)+(top_left_margin*2);
        var canvas_image_width = HTML_Width;
        var canvas_image_height = HTML_Height;

        var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
        canvas.getContext('2d');

        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jspdf('p', 'pt',  [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'JPG', top_left_margin, -150,canvas_image_width,canvas_image_height);


        for (var i = 1; i <= totalPDFPages; i++) { 
          pdf.addPage();
          pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
        }

        pdf.save("carPDF.pdf");
      });
    }
  }

  view_image(img_url) {
    this.view_img_url = img_url;
  }

  reloadUrl(brand,slug){
    window.location.href = "cars/"+brand+"/"+slug
     
   }

}
