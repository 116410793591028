<div id="home">
    <div class="home-slider">
        <div class="">
            <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators" *ngIf="sliders.length > 0">
                <li *ngFor="let slider of sliders" data-target="#carouselExampleCaptions" data-slide-to="slider.id" class="active"></li>
              </ol>
              <div class="carousel-inner" role="listbox" *ngIf="sliders.length > 0">
                <div class="carousel-item" [class]="{'active': slider_index == 0}" *ngFor="let slider of sliders;let slider_index=index">
                  <!-- <img class="d-block w-100" *ngIf="slider.slider_image.img != null" [src]="environment.image_base_url+'/car_slider_images/'+slider.slider_image.id+'/'+slider.slider_image.img" alt="First slide"> -->
                  <div class="d-block w-100"></div>
                  <div class="slider-text">
                    <div class="container h-100">
                        <div class="row h-100 align-items-center justify-content-center text-md-right text-sm-left">
                            <div class="col-lg-3 col-sm-12 mb-5 banner-img">
                              <a class="btn bold-text" [routerLink]="['/cars']" *ngIf="selected_language == null || selected_language == 'eng'">Find your car</a>
                              <a class="btn bold-text" [routerLink]="['/cars']" *ngIf="selected_language == 'spa'">Encuentra tu coche</a>
                              <a class="btn bold-text" [routerLink]="['/cars']" *ngIf="selected_language == 'ger'">Finde dein Auto</a>
                              <a class="btn bold-text" [routerLink]="['/cars']" *ngIf="selected_language == 'rus'">Найди свою машину</a>
                            </div>    
                            <div class="col-lg-9 col-sm-12">
                              <h1 class="text-uppercase text-white font-weight-bold">{{slider.title1}} <span>{{slider.title2}}</span></h1>
                                <p class="slider-para text-white text-white mb-5" [innerHTML]="slider.description">{{slider.description}}</p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
    </div> 
    <section id="slider-form">
        <div class="container">
          <div class="row horiztal-form">
              <div class="col-xl-10 col-md-12 col-12">
                  <div class="row bold-text" *ngIf="brands_arr.length > 0">
                      <div class="col-xl-3 col-md-6 col-12">
                        <div class="form-group ">
                            <label for="formGroupExampleInput" *ngIf="selected_language == null || selected_language == 'eng'">SELECT CAR</label>
                            <label for="formGroupExampleInput" *ngIf="selected_language == 'spa'">SELECCIONAR COCHE</label>
                            <label for="formGroupExampleInput" *ngIf="selected_language == 'ger'">AUTO AUSWÄHLEN</label>
                            <label for="formGroupExampleInput" *ngIf="selected_language == 'rus'">ВЫБЕРИТЕ АВТО</label>
                            <select id="ingredients-first" (change)="get_filters($event.target.value, 'brand')" *ngIf="selected_language == null || selected_language == 'eng'">
                              <option value="0">Select brand</option>
                              <option value="{{brand.id}}" *ngFor="let brand of brands_arr">{{brand.eng_title}}</option>
                            </select>
                            <select id="ingredients-first" (change)="get_filters($event.target.value, 'brand')" *ngIf="selected_language == 'spa'">
                              <option value="0">Seleccionar marca</option>
                              <option value="{{brand.id}}" *ngFor="let brand of brands_arr">{{brand.spa_title}}</option>
                            </select>
                            <select id="ingredients-first" (change)="get_filters($event.target.value, 'brand')" *ngIf="selected_language == 'ger'">
                              <option value="0">Marke auswählen</option>
                              <option value="{{brand.id}}" *ngFor="let brand of brands_arr">{{brand.ger_title}}</option>
                            </select>
                            <select id="ingredients-first" (change)="get_filters($event.target.value, 'brand')" *ngIf="selected_language == 'rus'">
                              <option value="0">Выберите марку</option>
                              <option value="{{brand.id}}" *ngFor="let brand of brands_arr">{{brand.rus_title}}</option>
                            </select>
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12">
                          <div class="form-group">
                            <label for="formGroupExampleInput" *ngIf="selected_language == null || selected_language == 'eng'">SELECT MODEL</label>
                            <label for="formGroupExampleInput" *ngIf="selected_language == 'spa'">SELECCIONAR MODELO</label>
                            <label for="formGroupExampleInput" *ngIf="selected_language == 'ger'">MODELL AUSWÄHLEN</label>
                            <label for="formGroupExampleInput" *ngIf="selected_language == 'rus'">ВЫБЕРИТЕ МОДЕЛЬ</label>
                              <select id="ingredients-second" (change)="get_filters($event.target.value, 'model')" *ngIf="selected_language == null || selected_language == 'eng'">
                                <option value="0">Select model</option>
                                <option value="{{car_model.id}}" *ngFor="let car_model of car_models">{{car_model.eng_title}}</option>
                              </select>
                              <select id="ingredients-second" (change)="get_filters($event.target.value, 'model')" *ngIf=" selected_language == 'spa'">
                                <option value="0">Seleccionar modelo</option>
                                <option value="{{car_model.id}}" *ngFor="let car_model of car_models">{{car_model.spa_title}}</option>
                              </select>
                              <select id="ingredients-second" (change)="get_filters($event.target.value, 'model')" *ngIf="selected_language == 'ger'">
                                <option value="0">Modell auswählen</option>
                                <option value="{{car_model.id}}" *ngFor="let car_model of car_models">{{car_model.ger_title}}</option>
                              </select>
                              <select id="ingredients-second" (change)="get_filters($event.target.value, 'model')" *ngIf="selected_language == 'rus'">
                                <option value="0">Выбрать модель</option>
                                <option value="{{car_model.id}}" class="car_model_field" *ngFor="let car_model of car_models">{{car_model.rus_title}}</option>
                              </select>
                            </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12">
                          <div class="form-group">
                            <label for="formGroupExampleInput" *ngIf="selected_language == null || selected_language == 'eng'">1ST REGISTRATION</label>
                            <label for="formGroupExampleInput" *ngIf="selected_language == 'spa'">1ER REGISTRO</label>
                            <label for="formGroupExampleInput" *ngIf="selected_language == 'ger'">1. REGISTRIERUNG</label>
                            <label for="formGroupExampleInput" *ngIf="selected_language == 'rus'">ПЕРВАЯ РЕГИСТРАЦИЯ</label>
                            <select  id="ingredients-third" (change)="get_filters($event.target.value, 'year')">
                              <option value="0" *ngIf="selected_language == null || selected_language == 'eng'">Select registration year</option>
                              <option value="0" *ngIf="selected_language == 'spa'">Seleccionar año de registro</option>
                              <option value="0" *ngIf="selected_language == 'ger'">Registrierungsjahr auswählen</option>
                              <option value="0" *ngIf="selected_language == 'rus'">Выберите год регистрации</option>
                              <option value="{{registration_year.registration_year}}" *ngFor="let registration_year of registration_years">{{registration_year.registration_year}}</option>
                            </select>
                          </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12">
                          <div class="form-group">
                              <label for="formGroupExampleInput" *ngIf="selected_language == null || selected_language == 'eng'">PRICE UNTIL</label>
                              <label for="formGroupExampleInput" *ngIf="selected_language == 'spa'">PRECIO HASTA</label>
                              <label for="formGroupExampleInput" *ngIf="selected_language == 'ger'">PREIS BIS</label>
                              <label for="formGroupExampleInput" *ngIf="selected_language == 'rus'">ЦЕНА ДО</label>
                              <select  id="ingredients-fourth">
                                <option value="0" *ngIf="selected_language == null || selected_language == 'eng'">Select price</option>
                                <option value="0" *ngIf="selected_language == 'spa'">Seleccionar precio</option>
                                <option value="0" *ngIf="selected_language == 'ger'">Preis auswählen</option>
                                <option value="0" *ngIf="selected_language == 'rus'">Выберите цену</option>
                                <option value="{{price.price}}" *ngFor="let price of search_filter_arr.prices">{{price.price}}</option>
                              </select>
                            </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-2 col-md-12 col-12">
                  <div class="form-group-btn">
                      <button class="btn form-btn" *ngIf="selected_language == null || selected_language == 'eng'" (click)="search_car();">Select Your Car</button>
                      <button class="btn form-btn" *ngIf="selected_language == 'spa'" (click)="search_car();">Buscar coche</button>
                      <button class="btn form-btn" *ngIf="selected_language == 'ger'" (click)="search_car();">Auto suchen</button>
                      <button class="btn form-btn" *ngIf="selected_language == 'rus'" (click)="search_car();">Поиск автомобиля</button>
                    </div>
              </div>
          </div>        
        </div>
    </section>
    <div class="clearfix"></div>
    <section id="whyus">
        <div class="container">
            <h2>{{why_us_btns_arr.title}}</h2>
            <div class="row">
                <div class="col-md-4 col-12" *ngFor="let why_us_btn of why_us_btns_arr.why_us_button_translations">
                   <div class="head"><img src="assets/images/icons/round-done-button.svg" alt="" /> {{why_us_btn.sub_title}}</div> 
                   <p class="description">{{why_us_btn.description}}</p>
                </div>
            </div>
        </div>
    </section>

    <section id="choose-car" *ngIf="environment.car_status.length > 0">
        <div class="back-color-main">
          <div class="container">
            <div class="row">
                <div class="col-md-6 col-12">
                    <h2 *ngIf="selected_language == null || selected_language == 'eng'">Choose your car</h2>
                    <h2 *ngIf="selected_language == 'spa'">Elige tu coche</h2>
                    <h2 *ngIf="selected_language == 'ger'">Wählen Sie Ihr Auto</h2>
                    <h2 *ngIf="selected_language == 'rus'">Выбери свою машину</h2>
                </div>
                <div class="col-md-3 col-12">
                    <div class="tab-container">
                      <div class="tab-navigation">              
                        <select id="select-box" class="custom-select" (change)=choose_car($event.target.value);>
                          <option value="{{car_status_index}}" *ngFor="let car_status of environment.car_status;let car_status_index=index">{{car_status[selected_language]}}</option>
                        </select>
                      </div>
                    </div>
                </div>
            </div> 
            <div class="row">
              <div class="col-md-12">
                <div class="tab-content" *ngIf="choose_car_arr.length > 0;else no_choosed_car_found">
                  <owl-carousel-o  [options]="customOptions">

                    <ng-template carouselSlide *ngFor="let choosed_car of choose_car_arr">
					  
                      <a [routerLink]="['/cars/'+choosed_car.car_brands.slug+'/'+choosed_car.slug]">
					  <div  [ngClass]="{'owl-carousel-o-b': choose_car_arr.length === 1}">
                        <div class="item" [ngClass]="{'owl-carousel-o-b2': choose_car_arr.length === 2}">
                        <div class="card">
                          <div class="card-header" *ngIf="choosed_car.car_feature_image != null">
                            <img [src]="environment.image_base_url+'/car_feature_images/'+choosed_car.id+'/'+choosed_car.car_feature_image.img_name" alt="" title="" />
                            <div class="overlay"></div>
                          </div>
                          <div class="card-body">
                            <div class="card-content">
                              <div class="card-text">
                                  <table class="table">
                                      <tbody>
										<tr *ngIf="selected_language == null || selected_language == 'eng'">
                                          <th class="tabhead">Name</th>
                                          <td>{{choosed_car.eng_title}}</td>
                                        </tr>
										
										 <tr *ngIf="selected_language == 'spa'">
                                          <th class="tabhead">Nombre</th>
                                         <td>{{choosed_car.eng_title}}</td>
                                        </tr>
                                        <tr *ngIf="selected_language == 'ger'">
                                          <th class="tabhead">Name</th>
                                          <td>{{choosed_car.eng_title}}</td>
                                        </tr>
                                        <tr *ngIf="selected_language == 'rus'">
                                          <th class="tabhead">имя</th>
                                         <td>{{choosed_car.eng_title}}</td>
                                        </tr>
										
                                        <tr *ngIf="selected_language == null || selected_language == 'eng'">
                                          <th class="tabhead">brand</th>
                                          <td>{{choosed_car.car_brands.eng_title}}</td>
                                        </tr>
                                        <tr *ngIf="selected_language == 'spa'">
                                          <th class="tabhead">marca</th>
                                          <td>{{choosed_car.car_brands.spa_title}}</td>
                                        </tr>
                                        <tr *ngIf="selected_language == 'ger'">
                                          <th class="tabhead">Marke</th>
                                          <td>{{choosed_car.car_brands.ger_title}}</td>
                                        </tr>
                                        <tr *ngIf="selected_language == 'rus'">
                                          <th class="tabhead">марка</th>
                                          <td>{{choosed_car.car_brands.rus_title}}</td>
                                        </tr>
              
                                        <tr *ngIf="selected_language == null || selected_language == 'eng'">
                                          <th class="tabhead">model</th>
                                          <td>{{choosed_car.car_models.eng_title}}</td>
                                        </tr>
                                        <tr *ngIf="selected_language == 'spa'">
                                          <th class="tabhead">modelo</th>
                                          <td>{{choosed_car.car_models.spa_title}}</td>
                                        </tr>
                                        <tr *ngIf="selected_language == 'ger'">
                                          <th class="tabhead">Modell-</th>
                                          <td>{{choosed_car.car_models.ger_title}}</td>
                                        </tr>
                                        <tr *ngIf="selected_language == 'rus'">
                                          <th class="tabhead">модель</th>
                                          <td>{{choosed_car.car_models.rus_title}}</td>
                                        </tr>
										
										<tr *ngIf="selected_language == null || selected_language == 'eng'">
										<th class="tabhead">Price</th>
										<td>{{choosed_car.price}}</td>
									  </tr>
									   <tr *ngIf="selected_language == 'spa'">
									   <th class="tabhead">Precio</th>
										<td>{{choosed_car.price}}</td>
									  </tr>
									  
									   <tr *ngIf="selected_language == 'ger'">
									   <th class="tabhead">Preis</th>
										<td>{{choosed_car.price}}</td>
									  </tr>
									  
										<tr *ngIf="selected_language == 'rus'">
									   <th class="tabhead">Цена</th>
										<td>{{choosed_car.price}}</td>
									  </tr>
              
                                        <tr>
                                          <th class="tabhead">km</th>
                                          <td>{{choosed_car.km}}</td>
                                        </tr>
              
                                        <tr>
                                          <th class="tabhead" *ngIf="selected_language == null || selected_language == 'eng'">year</th>
                                          <th class="tabhead" *ngIf="selected_language == 'spa'">año</th>
                                          <th class="tabhead" *ngIf="selected_language == 'ger'">Jahr</th>
                                          <th class="tabhead" *ngIf="selected_language == 'rus'">год</th>
                                          <td>{{choosed_car.registration_year}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                              </div>
                            </div>
                          </div>
                        </div>
						 <div class="card"></div>
                        </div>
						</div>
                      </a>
					  
                    </ng-template> 
                  </owl-carousel-o>
                </div>
                <ng-template #no_choosed_car_found>
                  No Car Found
                </ng-template>
              </div>
            </div>
        </div>
        </div>   
        
    </section>
    <section id="testimonial">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
             <h2 class="test-head">{{testimonial_main_title.title}}</h2>
          </div>
        </div>
        <div class="row" *ngIf="testimonials_arr.length > 0">

          <div id="carouseltes" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouseltes" [attr.data-slide-to]="testimonial_toggle_index" [class]="{'active': testimonial_toggle_index == 0}" *ngFor="let testimonial of testimonials_arr;let testimonial_toggle_index=index"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item" [class]="{'active': testimonial_index == 0}" *ngFor="let testimonial of testimonials_arr;let testimonial_index=index">
                <div class="row">
                  <div class="col-xl-12 col-lg-6 col-sm-12 col-12">
                    <div class="item">
                      <div class="test-data">
                        <img class="test-img" *ngIf="testimonial.img != null" [src]="environment.image_base_url+'/testimonials/'+testimonial.id+'/'+testimonial.img" alt="" title="" />
                        <div class="test-content">
                          <p class="description">{{testimonial.testimonial_translation.description}}</p>
                          <p class="author">{{testimonial.testimonial_translation.author_name}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouseltes" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouseltes" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>


          
        </div>
      </div>
    </section>

    <section id="getintoch" style="background-image:url({{parallax_img}})">
       <div class="container">
         <div class="row">
            <div class="col-12">
                <h4 [innerHTML]="parallax.title">{{parallax.title}}</h4>
                  <a [routerLink]="['/contact']"><button class="btn">{{parallax.btn_title}}</button></a>
            </div>
         </div>
       </div>
    </section>

    <section id="blog" *ngIf="blogs.length > 0">
      <div class="container">
        <div class="row">
           <div class="col-12  mt-3">
              <h2 *ngIf="selected_language == 'rus';else other_language">Блог</h2>
              
              <ng-template #other_language>
                <h2>Blog</h2>
              </ng-template>
           </div>
           <div class="col-xl-4 col-lg-4 col-sm-12 col-12" *ngFor="let blog of blogs">
            <div class="home-blog">
                <img class="homblog-img" [src]="environment.image_base_url+'/blog_images/'+blog.id+'/'+blog.img" alt="">
                <div class="datehead">
                  <p class="date"><img class="calendar" src="assets/images/icons/calendar.svg" alt="" title="" /> {{blog.created_at | date:'d MMM, y'}}</p>
                  <p class="tag">{{blog.category_translations.title}}</p>
                </div>
                <h4>{{blog.blog_translation.title}}</h4>
                <p class="description">{{blog.blog_translation.description | slice:0:200}}</p>
                <a [routerLink]="['/blog/'+blog.id]" class="btn" *ngIf="selected_language == 'eng'">See More</a>
                <a [routerLink]="['/blog/'+blog.id]" class="btn" *ngIf="selected_language == 'spa'">ver más</a>
                <a [routerLink]="['/blog/'+blog.id]" class="btn" *ngIf="selected_language == 'ger'">Mehr sehen</a>
                <a [routerLink]="['/blog/'+blog.id]" class="btn" *ngIf="selected_language == 'rus'">узнать больше</a>
            </div>
          </div>
          <!-- <div class="col-12  mt-5 text-center">
            <a href="mediapage.html"><button class="btn allnews">All News</button></a>
          </div> -->
        </div>
      </div>
   </section> 
    
</div>