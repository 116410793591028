<section class="main-investors" *ngIf="investor">
    <div class="container">
        <div class="row mb-5">
            <div class="col-xl-5 col-lg-6 col-sm-12 col-12 become">
              <div class="inve-text">
                  <h2>{{investor.title1}}  <span>{{investor.title2}}</span></h2>
                  <a><button class="btn">{{investor.btn_title}}</button></a> 
              </div>
          </div>
          <div class="col-xl-7 col-lg-6 col-sm-12 col-12">
              <div class="video">
                  <iframe class="youtubeplayer" width="100%" height="455" [src]="video_id" frameBorder="0"></iframe>
              </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 mt-5 mb-2">
              <h6>Description</h6>
          </div>
          <div class="col-xl-12 col-lg-12 col-sm-12 col-12">
              <p>
                {{investor.description}} 
              </p>
        </div>
      </div>
    </div>
</section>