<footer id="footer" [ngClass]="{'not-found': routeUrl === '/404'}">
<style>
.footer-links {
    color: #212529;
    line-height: 30px;
    font-family: "FuturaPTMedium";
    font-size: 20px;
}
</style>
 
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-sm-4 col-12">
            <div class="f-first">
              <img class="footer-logo" [src]="footer_img_url" alt="" title="" />
              <ul>
                <li><a target="_blank" href="https://www.facebook.com/carismaclassic/"><img class="social-img" src="assets/images/icons/facebook-letter-logo.svg" alt="" title="" /></a></li>
                <li><a target="_blank" href="https://twitter.com/CarismaClassic"><img class="social-img" src="assets/images/icons/twitter-2.svg" alt="" title="" /></a></li>
                <li><a target="_blank" href="https://www.instagram.com/carismaclassic/?hl=en"><img class="social-img" src="assets/images/icons/instagram-symbol.svg" alt="" title="" /></a></li>
              </ul>

              <p *ngIf="selected_language == 'eng'">© {{year}} Carisma Classic
                <br>All Rights Reserved</p>
                <p *ngIf="selected_language == 'spa'">© {{year}} Carisma Classic
                  <br>Reservados todos los derechos</p>
                  <p *ngIf="selected_language == 'ger'">© {{year}} Carisma Classic
                    <br>Alle Rechte vorbehalten</p>

              <p *ngIf="selected_language == 'rus'">© {{year}} Carisma Classic
                <br>Все права защищены</p>
                <p><a target="_blank" href="https://home.mobile.de/CARISMACLASSIC#ses"><img class="powered-img" src="assets/images/mobilede.png"></a></p>
            </div>
        </div>
        
        <div class="col-xl-4 col-sm-4 col-12"  >
          <div class="f-second">
             <h5>Quick Links</h5> 
            <ul *ngFor="let footer_menu of footer_menus">
				 <li  *ngFor="let sub_menu of footer_menu.sub_menus"><a [ngClass]="{'f-active': routeUrl=== '/'+sub_menu.redirection_url}"   [routerLink]="['/'+sub_menu.redirection_url]">{{sub_menu.sub_menu_translation.title}}</a></li>
			</ul>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-12" *ngIf="selected_language == 'eng'">
          <div class="f-five">
            <h5>Our showroom</h5>
            <ul>
              <li><img class="social-img" src="assets/images/icons/placeholder.svg" alt="" title="" /> Sorsumer Hauptstrasse 17, 31139 Hildesheim.</li>
              <li><img class="social-img" src="assets/images/icons/envelope-2.svg" alt="" title="" /> info@carismaclassic.com</li>
              <li><img class="social-img" src="assets/images/icons/phone-receiver.svg" alt="" title="" /> +0049 15110511385</li>
              <li><img class="social-img" src="assets/images/icons/clock-2.svg" alt="" title="" /> Mon - Fri: 9AM - 7 PM
                <br>Sat - Sun: 9AM - 2 PM</li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-12" *ngIf="selected_language == 'spa'">
          <div class="f-five">
            <h5>Nuestro showroom
            </h5>
            <ul>
              <li><img class="social-img" src="assets/images/icons/placeholder.svg" alt="" title="" />Sorsumer Hauptstrasse 17, 31139 Hildesheim.</li>
              <li><img class="social-img" src="assets/images/icons/envelope-2.svg" alt="" title="" /> info@carismaclassic.com</li>
              <li><img class="social-img" src="assets/images/icons/phone-receiver.svg" alt="" title="" /> +0049 15110511385</li>
              <li><img class="social-img" src="assets/images/icons/clock-2.svg" alt="" title="" />Lunes - Vie: 9AM - 7PM
                <br>Sábados - Domingos: 9AM - 2PM</li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-12" *ngIf="selected_language == 'ger'">
          <div class="f-five">
            <h5>Unser Showroom
            </h5>
            <ul>
              <li><img class="social-img" src="assets/images/icons/placeholder.svg" alt="" title="" /> Sorsumer Hauptstrasse 17, 31139 Hildesheim.</li>
              <li><img class="social-img" src="assets/images/icons/envelope-2.svg" alt="" title="" /> info@carismaclassic.com</li>
              <li><img class="social-img" src="assets/images/icons/phone-receiver.svg" alt="" title="" /> +0049 15110511385</li>
              <li><img class="social-img" src="assets/images/icons/clock-2.svg" alt="" title="" /> Mo - Fr: 9 - 19 Uhr
              <br>Sa - So: 9 - 14 Uhr
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 col-12" *ngIf="selected_language == 'rus'">
          <div class="f-five">
            <h5>Наш выставочный зал</h5>
            <ul>
              <li><img class="social-img" src="assets/images/icons/placeholder.svg" alt="" title="" />  Sorsumer Hauptstrasse 17, 31139 Hildesheim.</li>
              <li><img class="social-img" src="assets/images/icons/envelope-2.svg" alt="" title="" /> info@carismaclassic.com</li>
              <li><img class="social-img" src="assets/images/icons/phone-receiver.svg" alt="" title="" /> +0049 15110511385</li>
              <li><img class="social-img" src="assets/images/icons/clock-2.svg" alt="" title="" /> Пн - пт: 9.00 - 19.00
                <br>Сб - вс: 9.00 - 14.00
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="cookieConsent" *ngIf="selected_language == 'eng'">
      <!-- <div id="closeCookieConsent"><i class="ti-close"></i></div> -->
      We use cookies to improve browser experience, to personalize content and ads, to provide social media features
      and to analyze our traffic. Additional details are available in our <a href="privacy-policy.php"
          target="_blank">Privacy Policy.</a>
           <a href="javascript:void(0)"  class="cookieConsentOK">Accept </a> 
           <a href="javascript:void(0)"  class=" close_icon cookieConsentOK"><i id="closeCookieConsents"
          style="font-size: 20px;vertical-align: middle;" class="ti-close">X</i> </a>
  </div>
  <div id="cookieConsent" *ngIf="selected_language == 'spa'">
    <!-- <div id="closeCookieConsent"><i class="ti-close"></i></div> -->
    Usamos cookies para mejorar la experiencia del navegador, para personalizar el contenido y los anuncios, para proporcionar funciones de redes sociales.
    y analizar nuestro tráfico. Detalles adicionales están disponibles en nuestro <a href="privacy-policy.php"
        target="_blank">Política de privacidad.</a>
         <a href="javascript:void(0)"  class="cookieConsentOK">Aceptar</a> 
         <a href="javascript:void(0)"  class=" close_icon cookieConsentOK"><i id="closeCookieConsents"
        style="font-size: 20px;vertical-align: middle;" class="ti-close">X</i> </a>
</div>
<div id="cookieConsent" *ngIf="selected_language == 'ger'">
  
  <!-- <div id="closeCookieConsent"><i class="ti-close"></i></div> -->
  Wir verwenden Cookies, um das Browsererlebnis zu verbessern, Inhalte und Anzeigen zu personalisieren und Funktionen für soziale Medien bereitzustellen
      und unseren Verkehr zu analysieren. Weitere Details finden Sie in unserer <a href="privacy-policy.php"
      target="_blank">Datenschutz-Bestimmungen.</a>
       <a href="javascript:void(0)"  class="cookieConsentOK">Datenschutz-Bestimmungen</a> 
       <a href="javascript:void(0)"  class=" close_icon cookieConsentOK"><i id="closeCookieConsents"
      style="font-size: 20px;vertical-align: middle;" class="ti-close">X</i> </a>
</div>
<div id="cookieConsent" *ngIf="selected_language == 'rus'">
  <!-- <div id="closeCookieConsent"><i class="ti-close"></i></div> -->
  Мы используем файлы cookie, чтобы улучшить работу браузера, персонализировать контент и рекламу, а также предоставить функции социальных сетей.
      и анализировать наш трафик. Дополнительная информация доступна в нашем<a href="privacy-policy.php"
      target="_blank">Политика конфиденциальности.</a>
       <a href="javascript:void(0)"  class="cookieConsentOK">Принимать
      </a> 
       <a href="javascript:void(0)"  class=" close_icon cookieConsentOK"><i id="closeCookieConsents"
      style="font-size: 20px;vertical-align: middle;" class="ti-close">X</i> </a>
</div>
</footer> 
