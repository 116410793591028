import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import{ LanguageService } from '../services/language.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,  private LanguageService: LanguageService) { }
  selected_language = '';
  ngOnInit(): void {
    this.LanguageService.currentMessage.subscribe(message => {
      
      this.selected_language = message;
    })

    this.spinner.hide();
  }

}
