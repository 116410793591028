<div class="pagetitleback">
    <div class="patitle" *ngIf="selected_language == null || selected_language == 'eng'">Media page</div>
    <div class="patitle" *ngIf="selected_language == 'spa'">Página de medios</div>
    <div class="patitle" *ngIf="selected_language == 'ger'">Medienseite</div>
    <div class="patitle" *ngIf="selected_language == 'rus'">Страница СМИ</div>
</div>

<section class="blogmediapage" *ngIf="blogs.length > 0;">
    <div class="container">
        <div class="row mt-3">
            <div class="col-12 mt-5 mb-5">
                <h2 *ngIf="selected_language == 'rus';else other_language">Блог</h2>
                <ng-template #other_language>
                  <h2>Blog</h2>
                </ng-template>
            </div>
        </div>
        <div class="row d-flex">
            <div class="col-xl-5 col-lg-6 col-sm-12 col-12 pr-md-0 pr-sm-1 col-d">
                <div class="blog-info">
                    <p class="date"><img class="calendar" src="assets/images/icons/calendar.svg" alt="" title="">{{blogs[0].created_at | date:'d MMM, y'}}</p>
                    <h4>{{blogs[0].blog_translation.title}}</h4>
                    <p>{{blogs[0].blog_translation.description}}</p>
                </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-sm-12 col-12 pl-md-0 pr-sm-1 col-d" *ngIf="blogs[0].type == 'image';else main_video_type">
                <div class="bimg" style="background-image:url({{image_base_url}}/blog_images/{{blogs[0].id}}/{{blogs[0].img}})">
                  
                </div>
            </div>
            <ng-template #main_video_type>
                <div class="col-xl-7 col-lg-6 col-sm-12 col-12 pl-md-0 pr-sm-1 col-d">
                      <!-- <div id="video-container">
                      <img src="assets/images/media-blog.jpg" id="play-video" class="img-responsive1">
                      <iframe style="display:none; width: 100%; height: 635px;" id="video" src="https://www.youtube.com/embed/SzmioR4Dj8E" frameborder="0" allowfullscreen></iframe>                
                      </div> -->
                      <div id="video-container">
                        <a data-toggle="modal" data-target="#videomodel" (click)="play_video(blogs[0].youtube_video_id);">
                         <img [src]="image_base_url+'/blog_images/'+blogs[0].id+'/'+blogs[0].video_thumbnail" id="play-video" class="img-responsive1">
                         <div class="play">
                            <img src="assets/images/icons/play.svg" id="" class="">
                         </div>
                        </a>
                      </div>
                </div>
            </ng-template>
        </div>
    </div>
</section>
<section class="blog-mediaslider" *ngIf="blogs.length > 0">
    <div class="container">
        <div class="row mt-5 mb-1">
          <div class="col-md-12">
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let blog of blogs">
                <div class="post-slide">
                  <div id="video-container" *ngIf="blog.type == 'video'">
                      <a href="/blog/{{blog.id}}">
                       <img [src]="image_base_url+'/blog_images/'+blog.id+'/'+blog.video_thumbnail" id="play-video" class="img-responsive1">
                       <div class="play">
                        <img src="assets/images/icons/play.svg" id="" class="">
                        </div>
                    </a>
                  </div>
                  <a href="/blog/{{blog.id}}">
                    <div class="post-img" *ngIf="blog.type == 'image'">
                      <img [src]="image_base_url+'/blog_images/'+blog.id+'/'+blog.img" alt="">
                    </div>
                    <div class="post-content">
                        <div class="datehead">
                          <p class="date"><img class="calendar" src="assets/images/icons/calendar.svg" alt="" title=""> {{blog.created_at | date:'d MMM, y'}}</p>
                          <p class="tag">{{blog.category_translations.title}}</p>
                        </div>
                          <h4>{{blog.blog_translation.title}}</h4>
                    </div>
                  </a>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
</section>



<!-- Modal -->
<div class="modal" id="videomodel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
<div class="modal-content">
  <div class="modal-body">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <iframe style="width: 100%; height: 400px;" id="video" [src]="video_url" frameborder="0" allowfullscreen></iframe>
  </div>
  
</div>
</div>
</div>