import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import{ LanguageService } from '../services/language.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-investors',
  templateUrl: './investors.component.html',
  styleUrls: ['./investors.component.css']
})
export class InvestorsComponent implements OnInit {
  investor:any = [];
  video_id:any = '';
  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private toastr: ToastrService,
    private dom:DomSanitizer,
    private LanguageService: LanguageService,
    private titleService: Title
  ) 
  { 
    this.video_id = this.dom.bypassSecurityTrustResourceUrl("");  
  }

  ngOnInit(): void {
    this.titleService.setTitle("Investors");
    this.LanguageService.currentMessage.subscribe(message => {
      this.get_investor_page();
    });
  }

  // Function to get investor page data
  get_investor_page() {
    this.http.get<any>(environment.base_url+'/get_investor_page').subscribe(data => {
      if(data.success) {
        console.log(data) 
        this.investor = data.data.investor_translation;
        this.video_id = this.dom.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+data.data.youtube_video_id);
        this.spinner.hide();   
      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  }

}
