import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private spinner: NgxSpinnerService,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    let get_language_code = localStorage.getItem('selected_language');
    let language_code = 'eng';
    if(get_language_code != null) {
      language_code = get_language_code;
    }
    request = request.clone({
        setHeaders: {
        LanguageCode: language_code
        },
    });
    return next.handle(request);
  }
}