import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{ LanguageService } from '../services/language.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  environment = environment;
  submitted = false;
  selected_language = '';
  contact_form: FormGroup;
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private LanguageService: LanguageService,
    private fb: FormBuilder,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Contact");
    this.contact_form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      message: [''],
    });
    this.LanguageService.currentMessage.subscribe(message => {
      
      this.selected_language = message;
    })
    this.spinner.hide();
  }
  
  //function to send customer detail on server to send email
  send_email() {
    this.submitted = true
    if(this.contact_form.invalid) {
      return 
    }
    this.http.post<any>(environment.base_url+'/send_email', {
      name: this.contact_form.get('name').value,
      email: this.contact_form.get('email').value,
      phone: this.contact_form.get('phone').value,
      message: this.contact_form.get('message').value,
    }).subscribe(data => {
      if(data.success) {

      } 
      else {
        for(var i in data.msg) {
          this.spinner.hide();
          this.toastr.error(data.msg[i], "");
        }  
      }
    }),
    (error) => this.toastr.error(error.message, "")
  } 

  // Function to return 
  get get_errors() { 
    return this.contact_form.controls 
  }

}
