<section id="main-contact">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 pr-0 map-content">
              <div class="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d156672.25342729155!2d9.838472150807405!3d52.15243770362972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47baa531b2ec9ed3%3A0x1b1e5ad81af61c28!2sSorsumer%20Hauptstra%C3%9Fe%2017%2C%2031139%20Hildesheim%2C%20Germany!5e0!3m2!1sen!2sin!4v1618225665446!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                  <div class="pin">
                      <img src="assets/images/icons/map_img.png" alt="" />
                      <ul>
                          <li><span>MON-FRI:</span> 9 AM - 7 PM</li>
                          <li><span>SAT-SUN:</span> 9 AM - 2 PM</li>
                              
                      </ul>
                  </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-12 col-12 pl-0">
                <div class="contact-form-details">
                    <table>
                      <tr>
                        <td><span *ngIf="selected_language == 'eng'">ADDRESS</span><span *ngIf="selected_language == 'spa'">HABLA A</span><span *ngIf="selected_language == 'ger'">ADRESSE</span><span *ngIf="selected_language == 'rus'">АДРЕС</span></td>
                        
                        <td>Sorsumer Hauptstrasse 17, 31139 Hildesheim.</td>
                      </tr>
                      <tr>
                           <td><span *ngIf="selected_language == 'eng'">PHONE</span><span *ngIf="selected_language == 'spa'">TELÉFONO</span><span *ngIf="selected_language == 'ger'">TELEFON</span><span *ngIf="selected_language == 'rus'">ТЕЛЕФОН</span></td>
                        <td>+0049 15110511385</td>
                      </tr>
                      <tr>
                        <td><span>EMAIL</span></td>
                        <td>info@carismaclassic.com</td>
                      </tr>
                    </table>

                    
                    <ul>
                      <li><a target="_blank" href="https://www.facebook.com/carismaclassic/"><img class="social-img" src="assets/images/icons/facebook.svg" alt="" title="" /></a></li>
                      <li><a target="_blank" href="https://twitter.com/CarismaClassic"><img class="social-img" src="assets/images/icons/twitter.svg" alt="" title="" /></a></li>
                      <li><a target="_blank" href="https://www.instagram.com/carismaclassic/?hl=en"><img class="social-img" src="assets/images/icons/instagram.svg" alt="" title="" /></a></li>
                    </ul>
                    <div class="forms">
                      
                      <form *ngIf="contact_form" [formGroup]="contact_form" (ngSubmit)="send_email()">
                          <label>
                            <p class="label-txt" *ngIf="selected_language == 'eng'">Name:</p>
                            <p class="label-txt" *ngIf="selected_language == 'spa'">Имя:</p>
                            <p class="label-txt" *ngIf="selected_language == 'ger'">Name:</p>
                            <p class="label-txt" *ngIf="selected_language == 'rus'">Имя:</p>
                            <input formControlName="name" value="" type="text" class="input">
                            <span *ngIf="submitted && get_errors.name.errors && get_errors.name.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</span>
                            <div class="line-box">
                              <div class="line"></div>
                            </div>
                          </label>
                          <label>
                            <p class="label-txt" *ngIf="selected_language == 'eng'">Email:</p>
                            <p class="label-txt" *ngIf="selected_language == 'spa'">Correo electrónico:</p>
                            <p class="label-txt" *ngIf="selected_language == 'ger'"> Email:</p>
                            <p class="label-txt" *ngIf="selected_language == 'rus'">Электронное письмо:</p>
                            <input formControlName="email" value="" type="text" class="input">
                            <span *ngIf="submitted && get_errors.email.errors">
                              <span *ngIf="get_errors.email.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</span>
                              <span class="error small form-text invalid-feedback" for="validation-email" *ngIf="get_errors.email.errors.email">Email must be a valid email address</span>
                            </span>
                            <div class="line-box">
                              <div class="line"></div>
                            </div>
                          </label>
                          <label>
                            <p class="label-txt" *ngIf="selected_language == 'eng'">Phone:</p>
                            <p class="label-txt" *ngIf="selected_language == 'spa'">Teléfono:</p>
                            <p class="label-txt" *ngIf="selected_language == 'ger'">Telefon:</p>
                            <p class="label-txt" *ngIf="selected_language == 'rus'">Телефон:</p>
                            <input formControlName="phone" value="" type="text" class="input">
                            <span *ngIf="submitted && get_errors.phone.errors && get_errors.phone.errors.required" class="error small form-text invalid-feedback" for="validation-email">This field is required</span>
                            <div class="line-box">
                              <div class="line"></div>
                            </div>
                          </label>
                          <label>
                       
                              <p class="label-txt" *ngIf="selected_language == 'eng'">Message:</p>
                            <p class="label-txt" *ngIf="selected_language == 'spa'">Mensaje:</p>
                            <p class="label-txt" *ngIf="selected_language == 'ger'">
                              Botschaft:</p>
                            <p class="label-txt" *ngIf="selected_language == 'rus'">
                              Сообщение:</p>
                          
                              <input formControlName="message" value="" type="text" class="input">
                              <div class="line-box">
                                <div class="line"></div>
                              </div>
                          </label>
                          <button type="submit" *ngIf="selected_language == 'eng'">Send a message</button>
                          <button type="submit" *ngIf="selected_language == 'spa'">Enviar un mensaje
                          </button> 
                            <button type="submit" *ngIf="selected_language == 'ger'">Eine Nachricht schicken</button> 
                              <button type="submit" *ngIf="selected_language == 'rus'">Послать сообщение
                              </button>
                          
                        </form>
                    </div>
                </div>
          </div>
        </div>
    </div>
</section>